// https://a.yandex-team.ru/arcadia/taxi/uservices/services/fleet-forms/docs/yaml/api/selfreg.yaml

import {
  useMutation,
  useQuery,
  type UseMutationOptions,
  type UseQueryOptions,
} from "@superweb/api";

import type * as api from "../../generated/fleet-forms";
import { apiFetch } from "../../fetch";

type UseV1PersonalSubmitPostRequest =
  api.paths["/fleet-forms/selfreg/v1/personal/submit"]["POST"]["request"];
type UseV1PersonalSubmitPostResponse =
  api.paths["/fleet-forms/selfreg/v1/car/submit"]["POST"]["response"];

export type UseV1PersonalSubmitPostParams = Omit<
  UseV1PersonalSubmitPostRequest,
  "method" | "url"
>;

export type UseV1PersonalSubmitPostResult =
  | {
      ok: true;
    }
  | {
      ok: false;
      error: Extract<UseV1PersonalSubmitPostResponse, { status: 400 }>["body"];
    };

export type UseV1PersonalSubmitPostOptions = UseMutationOptions<
  UseV1PersonalSubmitPostResult,
  unknown,
  UseV1PersonalSubmitPostParams
>;

export const useV1PersonalSubmitPost = (
  options?: UseV1PersonalSubmitPostOptions,
) => {
  const url = "/api/fleet-forms/selfreg/v1/personal/submit";

  return useMutation(({ headers, body }) => {
    return apiFetch(
      new Request(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }),
      {
        200: async () => ({
          ok: true as const,
        }),
        400: async (r) => ({
          ok: false as const,
          error: await r.json(),
        }),
      },
    );
  }, options);
};

type UseV1CarSubmitPostRequest =
  api.paths["/fleet-forms/selfreg/v1/car/submit"]["POST"]["request"];
type UseV1CarSubmitPostResponse =
  api.paths["/fleet-forms/selfreg/v1/car/submit"]["POST"]["response"];

export type UseV1CarSubmitPostParams = Omit<
  UseV1CarSubmitPostRequest,
  "method" | "url"
>;

export type UseV1CarSubmitPostResult =
  | {
      ok: true;
    }
  | {
      ok: false;
      error: Extract<UseV1CarSubmitPostResponse, { status: 400 }>["body"];
    };

export type UseV1CarSubmitPostOptions = UseMutationOptions<
  UseV1CarSubmitPostResult,
  unknown,
  UseV1CarSubmitPostParams
>;

export const useV1CarSubmitPost = (options?: UseV1CarSubmitPostOptions) => {
  const url = "/api/fleet-forms/selfreg/v1/car/submit";

  return useMutation(({ headers, body }) => {
    return apiFetch(
      new Request(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }),
      {
        200: async () => ({
          ok: true as const,
        }),
        400: async (r) => ({
          ok: false as const,
          error: await r.json(),
        }),
      },
    );
  }, options);
};

type UseV1LicenseSubmitPostRequest =
  api.paths["/fleet-forms/selfreg/v1/license/submit"]["POST"]["request"];
type UseV1LicenseSubmitPostResponse =
  api.paths["/fleet-forms/selfreg/v1/license/submit"]["POST"]["response"];

export type UseV1LicenseSubmitPostParams = Omit<
  UseV1LicenseSubmitPostRequest,
  "method" | "url"
>;

export type UseV1LicenseSubmitPostResult =
  | {
      ok: true;
    }
  | {
      ok: false;
      error: Extract<UseV1LicenseSubmitPostResponse, { status: 400 }>["body"];
    };

export type UseV1LicenseSubmitPostOptions = UseMutationOptions<
  UseV1LicenseSubmitPostResult,
  unknown,
  UseV1LicenseSubmitPostParams
>;

export const useV1LicenseSubmitPost = (
  options?: UseV1LicenseSubmitPostOptions,
) => {
  const url = "/api/fleet-forms/selfreg/v1/license/submit";

  return useMutation(({ headers, body }) => {
    return apiFetch(
      new Request(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }),
      {
        200: async () => ({
          ok: true as const,
        }),
        400: async (r) => ({
          ok: false as const,
          error: await r.json(),
        }),
      },
    );
  }, options);
};

type UseV1AddressSuggestPostRequest =
  api.paths["/fleet-forms/v1/address/suggest"]["POST"]["request"];
type UseV1AddressSuggestPostResponse =
  api.paths["/fleet-forms/v1/address/suggest"]["POST"]["response"];

export type UseV1AddressSuggestPostParams = Omit<
  UseV1AddressSuggestPostRequest,
  "method" | "url"
>;

export type UseV1AddressSuggestPostResult =
  | {
      ok: true;
      data: Extract<UseV1AddressSuggestPostResponse, { status: 200 }>["body"];
    }
  | {
      ok: false;
      error: Extract<UseV1AddressSuggestPostResponse, { status: 400 }>["body"];
    };

export type UseV1AddressSuggestPostOptions =
  UseQueryOptions<UseV1AddressSuggestPostResult>;

export const useV1AddressSuggestPost = (
  { query, headers, body }: UseV1AddressSuggestPostParams,
  options?: UseV1AddressSuggestPostOptions,
) => {
  const url = "/api/fleet-forms/v1/address/suggest";

  return useQuery(
    [url, query, headers, body],
    () => {
      const searchParams = new URLSearchParams(query);
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "POST",
          headers,
          body: JSON.stringify(body),
        }),
        {
          200: async (r) => ({
            ok: true as const,
            data: await r.json(),
          }),
          400: async (r) => ({
            ok: false as const,
            error: await r.json(),
          }),
        },
      );
    },
    options,
  );
};

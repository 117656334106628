/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import {
  LinkButton,
  icons,
  useIsMobile,
  useTypo,
  useUiColors,
} from "@superweb/ui";

export const Banner = ({
  title,
  linkButtons,
}: {
  title: string;
  linkButtons: {
    text: string;
    href: string;
  }[];
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();

  return (
    <div
      css={{
        display: "grid",
        ...(isMobile
          ? {
              gridTemplateRows: "1fr auto",
              rowGap: "10px",
              ...cssFns.padding("10px"),
            }
          : {
              gridTemplateColumns: "1fr auto",
              columnGap: "8px",
              ...cssFns.padding("8px"),
            }),
        backgroundColor: uiColors.controlMinor,
        ...cssFns.border({
          radius: "16px",
        }),
        alignItems: "center",
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          columnGap: isMobile ? "10px" : "8px",
        }}
      >
        <div
          css={{
            color: uiColors.everFront,
            width: "40px",
            height: "40px",
            backgroundColor: uiColors.statuses.success,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...cssFns.border({
              radius: "10px",
            }),
          }}
        >
          <icons.EducationOutline />
        </div>
        <div
          css={{
            ...typo({
              level: "body2",
              weight: "regular",
              density: "tight",
            }),
            color: uiColors.text,
          }}
        >
          {title}
        </div>
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: `repeat(${linkButtons.length}, 1fr)`,
          columnGap: "8px",
        }}
      >
        {linkButtons.map((linkButton, index) => (
          <LinkButton
            key={index}
            text={linkButton.text}
            link={{ href: linkButton.href, external: true }}
            view="outline"
            size="xs"
          />
        ))}
      </div>
    </div>
  );
};

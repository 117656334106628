/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useState, type ReactNode } from "react";

import {
  DateField,
  ExperimentalForm,
  TextField,
  createDateFieldState,
  createTextFieldState,
  useTypo,
  useUiColors,
  type DateFieldState,
  type TextFieldState,
} from "@superweb/ui";
import { useLocale } from "@superweb/intl";

import { useV1LicenseSubmitPost } from "@fleet/api/fleet-forms";

import { Message, useMessage } from "#internal/intl";
import { useLogAction } from "#internal/analytics";
import {
  useErrorMessages,
  useValidate,
  useOnChangePartial,
  useOnShowErrors,
} from "#internal/utils";
import { SubmitBottomSheet } from "#internal/ui";

type LicenseFormState = {
  number: TextFieldState;
  issue_date: DateFieldState;
  expiry_date: DateFieldState;
};

export const DriverLicenseDetailForm = ({
  sessionId,
  refId,
  bottomSheetContent,
  onSumbit,
  onError,
  onSuccess,
  licenseTemplates,
}: {
  sessionId: string;
  refId: string;
  bottomSheetContent?: ReactNode;
  onSumbit: () => void;
  onError: (code?: string) => void;
  onSuccess: () => void;
  licenseTemplates?: string[];
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const message = useMessage();
  const logActionSubmit = useLogAction("submit");
  const logActionInvalid = useLogAction("invalid");
  const locale = useLocale();
  const errorMessages = useErrorMessages();

  const [formState, setFormState] = useState<LicenseFormState>(() => {
    return {
      number: createTextFieldState({
        value: "",
        errorMessage: errorMessages.requiredField,
      }),
      issue_date: createDateFieldState({
        errorMessage: errorMessages.requiredField,
      }),
      expiry_date: createDateFieldState({
        errorMessage: errorMessages.requiredField,
      }),
    };
  });

  const { mutate: submit, isLoading } = useV1LicenseSubmitPost({
    onSuccess(data) {
      if (data.ok) {
        onSuccess();
        onSumbit();
        logActionSubmit("submit_license");
      } else {
        switch (data.error.code) {
          case "invalid_driver_license": {
            logActionInvalid("invalid_driver_license", {
              onlyYM: true,
            });
            onChangePartial({
              number: {
                ...formState.number,
                errorMessage:
                  licenseTemplates && licenseTemplates.length > 0
                    ? errorMessages.invalidDriverLicenseWithTemplates(
                        licenseTemplates.join(", "),
                      )
                    : errorMessages.invalidDriverLicense,
                errorVisible: true,
              },
            });
            break;
          }
          case "invalid_issue_date": {
            logActionInvalid("invalid_issue_date", { onlyYM: true });
            onChangePartial({
              issue_date: {
                ...formState.issue_date,
                errorMessage: errorMessages.invalidDate,
                errorVisible: true,
              },
            });
            break;
          }
          case "invalid_expiry_date": {
            logActionInvalid("invalid_expiry_date", {
              onlyYM: true,
            });
            onChangePartial({
              expiry_date: {
                ...formState.expiry_date,
                errorMessage: errorMessages.invalidDate,
                errorVisible: true,
              },
            });
            break;
          }
          default: {
            onError(data.error.code);
            break;
          }
        }
      }
    },
    onError: () => onError(),
  });

  const onChangePartial = useOnChangePartial(setFormState);
  const onShowErrors = useOnShowErrors(setFormState);
  const { validateTextField, validateDateField } = useValidate();

  return (
    <ExperimentalForm
      main={
        <>
          <div
            css={{
              ...typo({
                level: "body2",
                density: "tight",
                weight: "regular",
              }),
              color: uiColors.textMinor,
            }}
          >
            <Message
              id="be435ebd-1277-4542-8ff7-81f87a331289"
              context="Yango forms. Driver details form. Step description"
              default="Add details to start earning right now"
            />
          </div>
          <TextField
            required
            label={message({
              id: "50988111-3283-4cd7-b7b2-95d2fdfcdf05",
              context: "Yango forms. Driver license",
              default: "Driver's license",
            })}
            state={formState.number}
            onChange={(state) => {
              const errorMessage = validateTextField(state.value);
              onChangePartial({
                number: {
                  ...state,
                  errorMessage,
                  errorVisible: !!errorMessage,
                },
              });
            }}
          />
          <DateField
            label={message({
              id: "02d879f9-1327-46de-acb2-81eee55f38f5",
              context: "Yango forms. Date of issue",
              default: "Issue date",
            })}
            state={formState.issue_date}
            onChange={(state) => {
              const errorMessage = validateDateField(state.value);
              onChangePartial({
                issue_date: {
                  ...state,
                  errorMessage,
                  errorVisible: !!errorMessage,
                },
              });
            }}
          />
          <DateField
            label={message({
              id: "776ba0e3-99e1-43be-ae7a-138b89219812",
              context: "Yango forms. Date of expire",
              default: "Expiration date",
            })}
            state={formState.expiry_date}
            onChange={(state) => {
              const errorMessage = validateDateField(state.value);
              onChangePartial({
                expiry_date: {
                  ...state,
                  errorMessage,
                  errorVisible: !!errorMessage,
                },
              });
            }}
          />
          <SubmitBottomSheet
            isLoading={isLoading}
            text={message({
              id: "ac83bdb3-d54b-4579-a672-e1f688919efe",
              context: "Yango forms. Add info button text",
              default: "Add details",
            })}
          >
            {bottomSheetContent}
          </SubmitBottomSheet>
        </>
      }
      onSubmit={() => {
        onShowErrors();
        const hasErrors = Object.keys(formState).some(
          (field) =>
            formState[field as keyof LicenseFormState].errorMessage !==
            undefined,
        );

        if (hasErrors) {
          if (
            (licenseTemplates &&
              licenseTemplates.length > 0 &&
              formState.number.errorMessage ===
                errorMessages.invalidDriverLicenseWithTemplates(
                  licenseTemplates.join(", "),
                )) ||
            formState.number.errorMessage === errorMessages.invalidDriverLicense
          ) {
            logActionInvalid("invalid_driver_license", {
              onlyYM: true,
            });
          }

          if (
            formState.expiry_date.errorMessage === errorMessages.invalidDate
          ) {
            logActionInvalid("invalid_expiry_date", {
              onlyYM: true,
            });
          }

          if (formState.issue_date.errorMessage === errorMessages.invalidDate) {
            logActionInvalid("invalid_issue_date", {
              onlyYM: true,
            });
          }

          return;
        }

        submit({
          headers: {
            "x-session-id": sessionId,
            language: locale.toString(),
            "content-type": "application/json",
          },
          body: {
            ref_id: refId,
            number: formState.number.value,
            issue_date: String(formState.issue_date.value?.toString()),
            expiry_date: String(formState.expiry_date.value?.toString()),
          },
        });
      }}
    />
  );
};

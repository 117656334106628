/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useState, useRef, type ReactNode } from "react";
import { mergeProps, useDatePicker, useFocusRing } from "react-aria";
import { useDatePickerState, type DatePickerStateOptions } from "react-stately";
import { Temporal } from "@js-temporal/polyfill";
import { CalendarDate, type DateValue } from "@internationalized/date";
import { useMessage } from "#intl";
import { useFormat } from "@superweb/intl";

import { Field } from "../fields/field";
import { FieldLabelV2 } from "../fields/label";
import { ClearButton } from "../fields/buttons";
import { FieldDescription } from "../fields/description";
import { FieldErrorMessage } from "../fields/error-message";
import { useDebouncedState } from "../state/state";
import { useIsMobile } from "../mobile-context";

import { DateInput } from "./date-input";
import { Calendar, CalendarButton, CalendarDialog } from "./calendar";
import {
  calendarDateToPlainDate,
  plainDateToCalendarDate,
} from "./date-transform";

type InvalidRules = {
  incomplete: boolean;
  required: boolean;
  min: boolean;
  max: boolean;
  exclude: boolean;
};

export type DateFieldState = {
  /**
   * Current input's value.
   */
  value?: Temporal.PlainDate;

  /**
   * Is set to `true` on change if the field contains partial or invalid date.
   */
  invalid?: boolean;

  /**
   * When set `false` the error message is not visible even if is set.
   * Is set on change depending on the interaction.
   * Can be set externally to force hide/show the error message.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorVisible?: boolean;

  /**
   * The error message associated with the field.
   * Visible only when `errorVisible` is `true`.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorMessage?: string;
};

export const DateField = ({
  label,
  description,
  ariaDescribedBy,
  state,
  min,
  max,
  icon,
  disabled = false,
  required = false,
  onChange,
  exclude,
  tooltip,
}: {
  /**
   * Text for field's label, that describes field's meaning.
   * No need to specify input examples in label's text.
   * The label is used for the accessibility of the element
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   * https://www.w3.org/WAI/tutorials/forms/labels/
   */
  label: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description
   */
  description?: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   * Use `ariaDescribedBy` when you need to use an element with text and additional content
   * (e.g., icons, images, or formatting) to describe the field.
   * This allows for associating the field with rich, external descriptions that provide additional context.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby
   */
  ariaDescribedBy?: string;

  /**
   * Current field's state.
   * The state stores the interactive fields, that change when interacting with the component.
   */
  state: DateFieldState;

  /**
   * If `true`, the date value is required.
   * If `true`, the clear button element isn't rendered.
   * @defaultValue false
   */
  required?: boolean;

  /**
   * The minimum allowed date that a user may select in popover calendar.
   */
  min?: Temporal.PlainDate;

  /**
   * The maximum allowed date that a user may select in popover calendar.
   */
  max?: Temporal.PlainDate;

  /**
   * Icon at the start of the field.
   */
  icon?: ReactNode;

  /**
   * If `true`, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Callback fired when the state is changed.
   * @param state - Recommended state for the component after the change.
   * @param info - Additional information about the filed at the current moment.
   */
  onChange: (
    state: DateFieldState,
    info: {
      invalid: {
        /**
         * If `true`, the date value is required.
         */
        required: boolean;

        /**
         * The minimum allowed date that a user may select in popover calendar.
         */
        min: boolean;

        /**
         * The maximum allowed date that a user may select in popover calendar.
         */
        max: boolean;

        /**
         * Excluded dates can't be selected in calendar.
         *
         */
        exclude: boolean;
      };
    },
  ) => void;

  /**
   * Excluded dates can't be selected in calendar.
   *
   * @returns true if date should be excluded
   */
  exclude?: (date: DateValue) => boolean;

  /**
   * Tooltip for each date cell.
   *
   * @returns tooltip content as a string or `undefined` if tooltip is not needed.
   */
  tooltip?: (state: {
    /**
     * Current date cell data
     */
    value: Temporal.PlainDate;
  }) => string | undefined;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const firstSegmentRef = useRef<HTMLDivElement>(null);
  const getErrorMessage = useErrorMessage();
  const [hasSegmentsWithValue, setHasSegmentsWithValue] = useState<boolean>(
    Boolean(state.value),
  );

  const handleChange = ({
    currentState,
    hasSegmentsWithValue,
  }: {
    currentState: DateFieldState;
    hasSegmentsWithValue: boolean;
  }) => {
    const currentValue = currentState.value;

    const invalidRules = validateDateFieldValue({
      value: currentValue,
      min,
      max,
      required,
      hasSegmentsWithValue,
      exclude,
    });

    const invalid = Object.values(invalidRules).some(Boolean);

    const errorMessage = invalid
      ? getErrorMessage({
          min,
          max,
          invalidRules,
        })
      : undefined;

    onChange(
      {
        invalid,
        value: currentValue,
        errorMessage,
        errorVisible: currentState.errorVisible,
      },
      {
        invalid: {
          required: invalidRules.required,
          min: invalidRules.min,
          max: invalidRules.max,
          exclude: invalidRules.exclude,
        },
      },
    );
  };

  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  const ariaProps: DatePickerStateOptions<DateValue> = {
    label,
    description,
    errorMessage: state.errorMessage,

    isInvalid: Boolean(state.errorVisible && state.errorMessage),

    // Typescript does not allow set null in value field, but in runtime null is valid value if date partial
    // https://github.com/adobe/react-spectrum/issues/1890
    // https://github.com/adobe/react-spectrum/issues/3187
    value: (state.value
      ? plainDateToCalendarDate(state.value)
      : null) as DateValue,

    maxValue: max ? plainDateToCalendarDate(max) : undefined,
    minValue: min ? plainDateToCalendarDate(min) : undefined,

    isDisabled: disabled,

    onChange: (value: DateValue | null) => {
      const hasSegmentsWithValue = true;
      setHasSegmentsWithValue(hasSegmentsWithValue);

      handleChange({
        currentState: {
          ...state,
          value: value ? calendarDateToPlainDate(value) : undefined,
          errorVisible: false,
        },
        hasSegmentsWithValue,
      });
    },
    onBlur: () => {
      handleChange({
        currentState: {
          ...state,
          errorVisible: true,
        },
        hasSegmentsWithValue,
      });
    },
  };

  const ariaState = useDatePickerState(ariaProps);

  const {
    groupProps,
    labelProps,
    descriptionProps,
    errorMessageProps,
    fieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(
    { ...ariaProps, "aria-describedby": ariaDescribedBy },
    ariaState,
    ref,
  );

  const isMobile = useIsMobile();

  // Workaround for https://github.com/adobe/react-spectrum/issues/1513
  // Corrects the triggering of clicks on elements under the overlay
  // by debouncing the open state
  const debouncedIsOpen = useDebouncedState(ariaState.isOpen, 0);
  // apply debounced state only on mobile in `true` -> `false` phase
  const isOpen = (isMobile && debouncedIsOpen) || ariaState.isOpen;

  const isShrunk = isFocused || isOpen || Boolean(state.value);

  const onInputChange = (hasSegmentsWithValue: boolean) => {
    setHasSegmentsWithValue(hasSegmentsWithValue);

    handleChange({
      currentState: {
        ...state,
        value: state.value,
      },
      hasSegmentsWithValue,
    });
  };

  const onClearButtonPress = () => {
    firstSegmentRef.current?.focus();
    const hasSegmentsWithValue = false;
    setHasSegmentsWithValue(hasSegmentsWithValue);

    handleChange({
      currentState: {
        ...state,
        value: undefined,
        errorVisible: false,
      },
      hasSegmentsWithValue,
    });
  };

  return (
    <>
      <Field
        fieldProps={mergeProps(groupProps, focusProps)}
        fieldRef={ref}
        focused={isFocused || isOpen}
        shrunk={isShrunk}
        disabled={disabled}
        icon={icon}
        label={
          <FieldLabelV2 shrunk={isShrunk} labelProps={labelProps}>
            {label}
          </FieldLabelV2>
        }
        input={
          <DateInput
            ariaPickerProps={fieldProps}
            firstSegmentRef={firstSegmentRef}
            onChange={onInputChange}
          />
        }
        clearButton={
          !required ? (
            <ClearButton
              visible={Boolean(state.value) && (isFocused || isOpen)}
              onPress={onClearButtonPress}
            />
          ) : null
        }
        toolbar={<CalendarButton isDisabled={disabled} {...buttonProps} />}
        descriptionAndError={
          state.errorVisible && state.errorMessage ? (
            <FieldErrorMessage errorMessageProps={errorMessageProps}>
              {state.errorMessage}
            </FieldErrorMessage>
          ) : (
            description && (
              <FieldDescription descriptionProps={descriptionProps}>
                {description}
              </FieldDescription>
            )
          )
        }
        onClick={() => {
          firstSegmentRef.current?.focus();
        }}
      />

      {isOpen && (
        <CalendarDialog
          state={ariaState}
          triggerRef={ref}
          ariaDialogProps={dialogProps}
        >
          <Calendar {...calendarProps} exclude={exclude} tooltip={tooltip} />
        </CalendarDialog>
      )}
    </>
  );
};

export const createDateFieldState = (
  defaultValue?: DateFieldState,
): DateFieldState => {
  return {
    value: undefined,
    invalid: false,
    errorVisible: false,
    errorMessage: undefined,
    ...defaultValue,
  };
};

export const useDateFieldState = (defaultValue?: DateFieldState) => {
  return useState<DateFieldState>(createDateFieldState(defaultValue));
};

const validateDateFieldValue = ({
  value,
  hasSegmentsWithValue,
  required,
  exclude,
  min,
  max,
}: {
  /**
   * Current input's value.
   */
  value?: Temporal.PlainDate;

  /**
   * Current input has value
   */
  hasSegmentsWithValue?: boolean;
  required?: boolean;
  exclude?: (date: DateValue) => boolean;
  min?: Temporal.PlainDate;
  max?: Temporal.PlainDate;
}): InvalidRules => {
  const invalid = {
    min: false,
    max: false,
    exclude: false,
    incomplete: Boolean(hasSegmentsWithValue && !value),
    required: Boolean(required && !value),
  };

  if (!value) {
    return invalid;
  }

  if (min) {
    invalid.min = Temporal.PlainDate.compare(value, min) < 0;
  }

  if (max) {
    invalid.max = Temporal.PlainDate.compare(value, max) > 0;
  }

  if (exclude) {
    const dateValue: DateValue = new CalendarDate(
      Number(value.year),
      Number(value.month),
      value.day,
    );

    invalid.exclude = exclude(dateValue);
  }

  return invalid;
};

const useErrorMessage = () => {
  const message = useMessage();
  const { formatDateTime } = useFormat();

  return ({
    min,
    max,
    invalidRules,
  }: {
    min?: Temporal.PlainDate;
    max?: Temporal.PlainDate;
    invalidRules: InvalidRules;
  }): string | undefined => {
    if (invalidRules.required) {
      return message({
        id: "6534fb4b-b526-46a4-b091-63a0ed041c7e",
        context:
          "Date field. Error message in platform libraries in the Date field component.",
        default: "Date required",
      });
    }

    if (invalidRules.max && max) {
      return message({
        id: "474fd8bb-776e-4a5b-97a8-0b9ffedb8d1b",
        context:
          "Date field. Error message in platform libraries in the Date field component.",
        default: "Date can't be after {max}",
        values: {
          max: formatDateTime(max, {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
        },
      });
    }

    if (invalidRules.min && min) {
      return message({
        id: "3f3ba5dd-43dc-42e1-8280-0a147d0872cf",
        context:
          "Date field. Error message in platform libraries in the Date field component.",
        default: "Date can't be before {min}",
        values: {
          min: formatDateTime(min, {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
        },
      });
    }

    if (invalidRules.exclude) {
      return message({
        id: "1a2aebda-65fc-427d-a9c5-8a97f3bc0bda",
        context:
          "Date field. Error message in platform libraries in the Date field component.",
        default: "Date unavailable",
      });
    }

    return message({
      id: "3802fa1b-3192-4ebd-af8f-98de8792b1f6",
      context:
        "Date field. Error message in platform libraries in the Date field component.",
      default: "Invalid date",
    });
  };
};

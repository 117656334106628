/**
 * DurationFormat implementation based on the proposal:
 * https://github.com/tc39/proposal-intl-duration-format
 *
 * Spec: https://tc39.es/proposal-intl-duration-format/
 *
 * Out of scope:
 * - Input types other than Temporal.Duration.
 *
 * Current limitations:
 * - No numberingSystem option.
 * - No "digital" duration style.
 * - No "2-digit" unit styles.
 * - No "numeric" style except for milliseconds.
 * - No smaller than millisecond units.
 * - No formatToParts.
 */

import type { Temporal } from "@js-temporal/polyfill";

export type DurationFormatOptions = {
  localeMatcher?: "best fit" | "lookup";
  style?: "long" | "short" | "narrow";
  yearsDisplay?: "always" | "auto";
  monthsDisplay?: "always" | "auto";
  weeksDisplay?: "always" | "auto";
  daysDisplay?: "always" | "auto";
  hoursDisplay?: "always" | "auto";
  minutesDisplay?: "always" | "auto";
  seconds?: "long" | "short" | "narrow";
  secondsDisplay?: "always" | "auto";
  milliseconds?: "long" | "short" | "narrow" | "numeric";
  millisecondsDisplay?: "always" | "auto";
  fractionDigits?: number;
};

export class DurationFormat {
  #locales: string | string[] | undefined;
  #options: DurationFormatOptions | undefined;

  constructor(locales?: string | string[], options?: DurationFormatOptions) {
    this.#locales = locales;
    this.#options = options;
  }

  format(value: Temporal.Duration) {
    return new Intl.ListFormat(this.#locales, {
      localeMatcher: this.#options?.localeMatcher,
      type: "unit",
      style: this.#options?.style ?? "short",
    }).format(this.#parts(value));
  }

  static supportedLocalesOf(
    locales: string | string[],
    options?: DurationFormatOptions,
  ) {
    const numberFormatSupportedLocales = Intl.NumberFormat.supportedLocalesOf(
      locales,
      options && {
        localeMatcher: options.localeMatcher,
      },
    );
    const listFormatSupportedLocales = Intl.ListFormat.supportedLocalesOf(
      locales,
      options && {
        localeMatcher: options.localeMatcher,
      },
    );
    return numberFormatSupportedLocales.filter((item) =>
      listFormatSupportedLocales.includes(item),
    );
  }

  #parts(duration: Temporal.Duration) {
    const parts: string[] = [];
    for (const unit of [
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds",
    ] as const) {
      if (unit === "seconds" && this.#options?.milliseconds === "numeric") {
        const value = duration.seconds + duration.milliseconds / 1000;
        if (value !== 0 || this.#options.secondsDisplay === "always") {
          parts.push(
            new Intl.NumberFormat(this.#locales, {
              localeMatcher: this.#options.localeMatcher,
              style: "unit",
              unit: "second",
              unitDisplay:
                this.#options.seconds ?? this.#options.style ?? "short",
              minimumFractionDigits: this.#options.fractionDigits,
              maximumFractionDigits: this.#options.fractionDigits,
            }).format(value),
          );
        }
        break;
      }

      if (
        duration[unit] !== 0 ||
        this.#options?.[`${unit}Display`] === "always"
      ) {
        parts.push(
          new Intl.NumberFormat(this.#locales, {
            localeMatcher: this.#options?.localeMatcher,
            style: "unit",
            unitDisplay: this.#options?.style ?? "short",
            unit: unit.slice(0, -1),
          }).format(duration[unit]),
        );
      }
    }

    return parts;
  }
}

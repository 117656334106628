import { useMemo } from "react";

import type { ComboBoxOption, ComboBoxState } from "@superweb/ui";
import type { UseV1AddressSuggestPostResult } from "@fleet/api/fleet-forms";

type AddressArray = Extract<
  UseV1AddressSuggestPostResult,
  { ok: true }
>["data"]["addresses"];

export const getAddressValue = (state: ComboBoxState<ComboBoxOption>) =>
  [state.value?.description, state.value?.label].filter(Boolean).join(" · ");

export const useAddressOptions = ({
  addresses,
}: {
  addresses: AddressArray;
}) => {
  return useMemo(() => {
    const addressMap: Record<string, boolean> = {};

    return addresses.reduce<ComboBoxOption[]>((acc, option) => {
      const { country, province, district, city, street, house } = option;
      const description = [country, province, district]
        .filter(Boolean)
        .join(", ");
      const label = [city, street, house].filter(Boolean).join(", ");
      const key = description + label;

      if (!addressMap[key] && label) {
        acc.push({ key, label, description });
        addressMap[key] = true;
      }

      return acc;
    }, []);
  }, [addresses]);
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useFontFace } from "@superweb/css";
import { useIsMobile, useTypo, useUiColors } from "@superweb/ui";

import { YangoLogo, YandexLogo, YandexIntLogo } from "../images/logo";
import { useBrand } from "../router";

import yhTextXBold from "./fonts/YangoHeadline-Black.woff";
import { LanguageSelector } from "./language-selector";

export const PageHeader = ({
  logoHeight,
  partnerName,
}: {
  logoHeight: number;
  partnerName?: string;
}) => {
  const typo = useTypo();
  const brand = useBrand();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();
  const fontFace = useFontFace();

  const yangoBrand = brand === "yango";
  const yandexRuLocation = /\.yandex\.ru$/.test(window.location.hostname);

  return (
    <header
      css={{
        position: "sticky",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <div
        css={{
          ...(yangoBrand
            ? {
                fontFamily: `${fontFace({
                  src: `url(${yhTextXBold})`,
                  fontWeight: "900",
                  fontDisplay: "swap",
                })}, sans-serif`,
                fontFeatureSettings: "'pnum', 'lnum'",
                fontWeight: "900",
                fontSize: isMobile ? "32px" : "48px",
                lineHeight: isMobile ? "34px" : "50px",
                letterSpacing: "0",
              }
            : typo({
                level: "title1",
                density: "normal",
                weight: "medium",
              })),
          color: uiColors.text,
          display: "grid",
          ...(isMobile
            ? { gridTemplateRows: "auto 1fr" }
            : { gridTemplateColumns: "auto 1fr" }),
          alignItems: "end",
        }}
      >
        <div
          css={{
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
              ...(isMobile
                ? {
                    paddingInlineStart: yangoBrand ? "8px" : "0",
                    justifyContent: "center",
                  }
                : {
                    paddingInlineEnd: yangoBrand ? "16px" : "20px",
                    justifyContent: "end",
                  }),
            }}
          >
            {yangoBrand ? (
              <YangoLogo height={logoHeight} width={isMobile ? 180 : 200} />
            ) : yandexRuLocation ? (
              <YandexLogo height={logoHeight} width={isMobile ? 180 : 200} />
            ) : (
              <YandexIntLogo height={logoHeight} width={isMobile ? 180 : 200} />
            )}
          </div>
          <div
            css={{
              ...(isMobile
                ? { justifyContent: "center" }
                : {
                    borderInlineStartColor: uiColors.text,
                    borderInlineStartStyle: "solid",
                    borderInlineStartWidth: "2px",
                    maxWidth: "242px",
                    paddingInlineStart: "20px",
                  }),
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              css={{
                ...(isMobile && { textAlign: "center" }),
              }}
            >
              {partnerName}
            </span>
          </div>
        </div>
      </div>
      <div
        css={{
          position: "absolute",
          insetInlineEnd: "0",
          ...(isMobile
            ? {
                insetBlockStart: "12px",
              }
            : {
                insetBlockStart: "20px",
              }),
        }}
      >
        <LanguageSelector view={isMobile ? "shortened" : "default"} />
      </div>
    </header>
  );
};

import { createContext, useCallback, useContext, type ReactNode } from "react";

import { useLocale } from "@superweb/intl";

import { useV1NewYearStoryListGet } from "@fleet/api/fleet-reports";

type NewYearStoryContextType = {
  parkId: string;
  brand: string;
  onSlideStart: (id: string, index: number, isLastSlide: boolean) => void;
  onNextSlide: (
    id: string,
    index: number,
    trigger: "click" | "timer" | "keyboard",
  ) => void;
  onPrevSlide: (
    id: string,
    index: number,
    trigger: "click" | "keyboard",
  ) => void;
  onStoryClose: (id: string, trigger: "click" | "timer") => void;
  onStoryShow: (id: string, index: number) => void;
  onActionSlide: (
    idStory: string,
    indexSlide: number,
    typeAction: "primary" | "secondary",
  ) => void;
  onGiftShow: (id: string) => void;
  onGiftClick: (id: string) => void;
};

const NewYearStoryContext = createContext<NewYearStoryContextType | null>(null);

export const useNewYearStoryContext = () => {
  const ctx = useContext(NewYearStoryContext);

  if (!ctx) {
    throw Error("Use useNewYearStoryContext in NewYearStoryContextProvider");
  }

  return ctx;
};

export const NewYearStoryContextProvider = ({
  parkId,
  brand,
  children,
  onSlideShow,
  onLastSlideShow,
  onNextSlide,
  onPrevSlide,
  onStoryClose,
  onStoryShow,
  onActionSlide,
  onGiftShow,
  onGiftClick,
}: {
  parkId: string;
  brand: string;
  children: ReactNode;
  onSlideShow: (id: string, index: number) => void;
  onLastSlideShow: (id: string) => void;
  onNextSlide: (
    id: string,
    index: number,
    trigger: "click" | "timer" | "keyboard",
  ) => void;
  onPrevSlide: (
    id: string,
    index: number,
    trigger: "click" | "keyboard",
  ) => void;
  onStoryClose: (id: string, trigger: "click" | "timer") => void;
  onStoryShow: (id: string, index: number) => void;
  onActionSlide: (
    idStory: string,
    indexSlide: number,
    typeAction: "primary" | "secondary",
  ) => void;
  onGiftShow: (id: string) => void;
  onGiftClick: (id: string) => void;
}) => {
  const handleSlideStart = useCallback(
    (id: string, index: number, isLastSlide: boolean) => {
      onSlideShow(id, index);

      if (!isLastSlide) return;

      onLastSlideShow(id);
    },
    [onLastSlideShow, onSlideShow],
  );

  return (
    <NewYearStoryContext.Provider
      value={{
        parkId,
        brand,
        onSlideStart: handleSlideStart,
        onGiftClick,
        onGiftShow,
        onActionSlide,
        onNextSlide,
        onPrevSlide,
        onStoryClose,
        onStoryShow,
      }}
    >
      {children}
    </NewYearStoryContext.Provider>
  );
};

export const useShowNewYearGift = ({
  placementId,
  parkId,
}: {
  placementId:
    | "introduction"
    | "contractors"
    | "vehicles"
    | "orders"
    | "payouts"
    | "home"
    | "yango";
  parkId: string;
}) => {
  const locale = useLocale();

  const { data } = useV1NewYearStoryListGet({
    headers: {
      "x-park-id": parkId,
      language: locale.toString(),
    },
  });

  if (!data?.ok) {
    return false;
  }

  if (data.payload.statistics_available) {
    if (placementId === "yango") {
      return true;
    }
    if (!data.payload.statistics_data) {
      return false;
    }
    const isStoryShown = !!data.payload.statistics_data.story_list.find(
      (story) => story.story_id === placementId,
    );
    if (data.payload.statistics_data.total_story_count === 5) {
      return !isStoryShown;
    } else {
      return placementId !== "payouts" && !isStoryShown;
    }
  }

  return false;
};

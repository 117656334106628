/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Helmet } from "react-helmet-async";

import { useUiColors, useTypo } from "@superweb/ui";
import { cssFns } from "@superweb/css";

import info from "../images/info.png";

import { useMessage } from "#internal/intl";

export const NotFound = () => {
  const typo = useTypo();
  const message = useMessage();

  const uiColors = useUiColors();

  return (
    <>
      <Helmet>
        <title>
          {message({
            id: "8b93dcf9-77b3-41ce-88ef-63d10a81a4fb",
            context: "404 page. Tab title",
            default: "No results found",
          })}
        </title>
      </Helmet>
      <div
        css={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: uiColors.background,
        }}
      >
        <div
          css={{
            display: "grid",
            rowGap: "30px",
            textAlign: "center",
            ...cssFns.placeItems("center"),
          }}
        >
          <img src={info} css={{ height: "270px" }} />
          <div css={{ display: "grid", textAlign: "center" }}>
            <span
              css={typo({ level: "body1", weight: "bold", density: "tight" })}
            >
              {message({
                id: "8ae0657d-2f1b-4cb2-b147-4174b187f506",
                context:
                  "404 page. Text that informs there is nothing on this route",
                default: "This page doesn't exist",
              })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useState, type ReactNode } from "react";

import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { useTypo, useUiColors, icons } from "@superweb/ui";

import { Tooltip, useDriverStatusColors } from ".";
import { useMessage } from "#intl";

const newYearBallBusySvg = new URL(
  "./images/new-year-ball-busy.svg",
  import.meta.url,
).href;
const newYearBallAvailableSvg = new URL(
  "./images/new-year-ball-available.svg",
  import.meta.url,
).href;
const newYearBallOfflineSvg = new URL(
  "./images/new-year-ball-offline.svg",
  import.meta.url,
).href;
const newYearBallOnRideSvg = new URL(
  "./images/new-year-ball-on-ride.svg",
  import.meta.url,
).href;

type Size = "xs" | "m";

const Badge = ({
  children,
  color,
  status,
  visible,
  isTooltipHidden,
  isNewYear,
}: {
  children: ReactNode;
  color?: string;
  status?: string;
  visible?: boolean;
  isTooltipHidden?: boolean;
  isNewYear?: boolean;
}) => {
  const uiColors = useUiColors();
  const message = useMessage();
  const {
    textInfo: { direction },
  } = useLocale();

  const driverColors = useDriverStatusColors();

  const imageUrl = color
    ? {
        [driverColors.busy]: newYearBallBusySvg,
        [driverColors.available]: newYearBallAvailableSvg,
        [driverColors.offline]: newYearBallOfflineSvg,
        [driverColors.inTransit]: newYearBallOnRideSvg,
      }[color] ?? newYearBallOfflineSvg
    : newYearBallOfflineSvg;

  const statusBadge = (
    <div
      css={{
        position: "absolute",
        insetInlineEnd: "0",
        insetBlockEnd: "0",
        bottom: "0",
        width: "14px",
        height: "14px",
        backgroundColor: uiColors.background,
        ...cssFns.border({ radius: "50%" }),
      }}
    >
      {isNewYear ? (
        <div
          css={{
            position: "absolute",
            top: "0px",
            left: "0px",
            ...(direction === "rtl"
              ? { transform: `rotate(-52deg)` }
              : undefined),
          }}
        >
          <div
            css={{
              width: "16px",
              height: "16px",
              transform: "rotate(26deg)",
              backgroundColor: uiColors.background,
              ...cssFns.border({ radius: "50%" }),
            }}
          />
          <div
            css={{
              position: "absolute",
              left: "7.3px",
              top: "-3px",
              width: "8.3px",
              height: "7px",
              transform: "rotate(26deg)",
              backgroundColor: uiColors.background,
              ...cssFns.border({ radius: "1px" }),
            }}
          />
          <img
            src={imageUrl}
            css={{
              position: "absolute",
              top: "-3px",
              left: "0px",
              width: "18px",
              height: "18px",
              transform: "rotate(26deg)",
            }}
          />
        </div>
      ) : (
        <div
          aria-label={message({
            id: "ebbea2cf-04e2-428a-bb5e-636b5c32f7cf",
            context: "aria-label for avatar status",
            default: "User status",
          })}
          css={{
            position: "absolute",
            top: "2px",
            left: "2px",
            width: "10px",
            height: "10px",
            backgroundColor: color,
            ...cssFns.border({ radius: "50%" }),
          }}
        />
      )}
    </div>
  );

  return (
    <div
      css={{
        position: "relative",
        ...(visible && cssFns.padding("4px")),
      }}
    >
      {children}
      {visible && color && status && (
        <div
          css={{
            position: "absolute",
            insetInlineEnd: "0",
            insetBlockEnd: "0",
          }}
        >
          {!isTooltipHidden ? (
            <Tooltip text={status} icon={() => statusBadge} />
          ) : (
            statusBadge
          )}
        </div>
      )}
    </div>
  );
};

export const Avatar = ({
  url,
  alt,
  initials,
  size = "m",
  badgeColor,
  badgeTooltipText,
  grayscale,
  isTooltipHidden,
  isNewYear,
}: {
  url?: string;
  alt: string;
  initials: string;
  size?: Size;
  badgeColor?: string;
  badgeTooltipText?: string;
  grayscale?: boolean;
  isTooltipHidden?: boolean;
  isNewYear?: boolean;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const [imageState, setImageState] = useState<"idle" | "error" | "loaded">(
    "idle",
  );

  return (
    <Badge
      isTooltipHidden={isTooltipHidden}
      color={badgeColor}
      status={badgeTooltipText}
      visible={Boolean(badgeColor && badgeTooltipText)}
      isNewYear={isNewYear}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: size === "xs" ? "24px" : "40px",
          height: size === "xs" ? "24px" : "40px",
          ...cssFns.border({ radius: "8px" }),
          ...cssFns.overflow("hidden"),
          backgroundColor: uiColors.controlMinor,
          userSelect: "none",
        }}
      >
        {url && imageState !== "error" ? (
          <img
            css={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              ...(grayscale && { filter: "grayscale(100%)" }),
            }}
            src={url}
            alt={alt}
            onLoad={() => setImageState("loaded")}
            onError={() => setImageState("error")}
          />
        ) : (
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...(size === "xs"
                ? {
                    ...cssFns.padding("5px", "0"),
                    ...typo({
                      level: "caption1",
                      weight: "medium",
                      density: "tight",
                    }),
                  }
                : {
                    ...cssFns.padding("10px", "6px"),
                    ...typo({
                      level: "body1",
                      weight: "medium",
                      density: "tight",
                    }),
                  }),
              color: uiColors.textMinor,
              textTransform: "uppercase",
            }}
          >
            {initials ? initials : <icons.User />}
          </div>
        )}
      </div>
    </Badge>
  );
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";

import {
  BaseTextField,
  createBaseTextFieldState,
  useBaseTextFieldState,
} from "../fields/base-text-field";

export type TextFieldState = {
  /**
   * Current input's value.
   */
  value: string;

  /**
   * When set `false` the error message is not visible even if is set.
   * Is set on change depending on the interaction.
   * Can be set externally to force hide/show the error message.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorVisible?: boolean;

  /**
   * The error message associated with the field.
   * Visible only when `errorVisible` is `true`.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorMessage?: string;
};

/**
 * Text Field let users enter and edit text.
 */
export const TextField = (props: {
  /**
   * Text for field's label, that describes field's meaning.
   * No need to specify input examples in label's text.
   * The label is used for the accessibility of the element
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   * https://www.w3.org/WAI/tutorials/forms/labels/
   */
  label: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description
   */
  description?: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   * Use `ariaDescribedBy` when you need to use an element with text and additional content
   * (e.g., icons, images, or formatting) to describe the field.
   * This allows for associating the field with rich, external descriptions that provide additional context.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby
   */
  ariaDescribedBy?: string;

  /**
   * Text for input's placeholder.
   * Temporary text that displays in field when it is empty.
   */
  placeholder?: string;

  /**
   * If `true`, the input element is required.
   * @defaultValue false
   */
  required?: boolean;

  /**
   * 	If `true`, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Current field's state.
   * The state stores the interactive fields, that change when interacting with the component.
   */
  state: TextFieldState;

  /**
   * Icon at the start of the field.
   */
  icon?: ReactNode;

  /**
   * If `true`, the component will receive focus by default.
   * @defaultValue false
   */
  autoFocus?: boolean;

  /**
   * Callback fired when the state is changed.
   * @param state - Recommended state for the component after the change.
   */
  onChange: (state: TextFieldState) => void;
}) => {
  return <BaseTextField {...props} />;
};

export const createTextFieldState = (
  defaultValue?: TextFieldState,
): TextFieldState => {
  return createBaseTextFieldState<TextFieldState>(defaultValue);
};

export const useTextFieldState = (defaultValue?: TextFieldState) => {
  return useBaseTextFieldState<TextFieldState>(defaultValue);
};

import type { SVGProps } from "react";

export const YangoLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="86" height="30" viewBox="0 0 86 30" fill="none" {...props}>
    <path
      d="M9.65702 8.27989C9.58302 9.50089 9.47202 10.9809 9.39802 11.4619C9.58302 11.0179 10.138 9.87089 10.841 8.61289L14.578 1.73089H21.127L9.80502 19.0839L6.84502 28.0009H0.999023L4.10702 18.6399L4.18102 1.73089H9.95302L9.65702 8.27989Z"
      fill="#FF4930"
    />
    <path
      d="M22.7955 1.73089H30.6765L26.8655 28.0009H21.2415L22.0555 23.2649H17.3565L15.1365 28.0009H9.17949L22.7955 1.73089ZM19.7245 18.3069H22.9435L24.3865 10.0929C24.6085 8.87189 24.9045 7.42889 24.9785 6.94789C24.7935 7.39189 24.3125 8.57589 23.6835 9.87089L19.7245 18.3069Z"
      fill="#FF4930"
    />
    <path
      d="M41.7311 14.6439C41.6571 15.8649 41.5461 17.3449 41.5091 17.8259C41.6571 17.3819 41.9901 16.1609 42.4711 14.7919L46.7631 1.73089H52.1281L43.3961 28.0009H37.1801L37.9941 14.4219C38.0681 13.2009 38.2161 11.7209 38.2531 11.2399C38.1051 11.6839 37.7721 12.9049 37.2911 14.2739L32.7771 28.0009H27.4121L36.1441 1.73089H42.5081L41.7311 14.6439Z"
      fill="#FF4930"
    />
    <path
      d="M55.7793 18.0849L57.4073 13.1269H65.3993L60.4413 28.0009H56.2603L56.6303 25.8919C55.3353 27.4829 54.0773 28.1859 52.2643 28.1859C47.0473 28.1859 46.8253 21.1559 49.3043 13.5709C52.0053 5.31989 55.8533 1.21289 60.7003 1.21289C64.9183 1.21289 66.7313 4.28389 66.9533 8.09489L61.7363 9.83389C61.5513 6.83689 60.9963 5.98589 60.1453 5.98589C58.6283 5.98589 57.2963 7.87289 55.1133 14.5329C53.5593 19.2689 52.8563 23.6719 54.7063 23.6719C56.3343 23.6719 57.6663 20.8969 58.4803 18.4179L58.5913 18.0849H55.7793Z"
      fill="#FF4930"
    />
    <path
      d="M71.0218 28.4819C63.8808 28.4819 63.8438 21.1559 66.3228 13.5709C68.9868 5.31989 72.9828 1.24989 77.5338 1.24989C84.6748 1.24989 84.7118 8.57589 82.2328 16.1609C79.5688 24.4119 75.5728 28.4819 71.0218 28.4819ZM71.6138 23.7829C72.8718 23.7829 74.2408 21.9699 76.4238 15.1989C77.8668 10.7589 79.1248 5.94889 76.9418 5.94889C75.6838 5.94889 74.3148 7.76189 72.1318 14.5329C70.6888 18.8989 69.3938 23.7829 71.6138 23.7829Z"
      fill="#FF4930"
    />
  </svg>
);

export const YandexLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="164" height="28" viewBox="0 0 164 28" fill="none" {...props}>
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M16.555 26H12.32v-8.68h-2.17L5.005 26H.525l5.67-9.38c-1.61-.537-2.847-1.389-3.71-2.555-.863-1.19-1.295-2.672-1.295-4.445 0-1.354.21-2.52.63-3.5.42-1.004 1.027-1.832 1.82-2.485.793-.654 1.75-1.144 2.87-1.47 1.12-.327 2.368-.49 3.745-.49h6.3V26ZM10.22 4.965c-1.47 0-2.625.373-3.465 1.12-.84.746-1.26 1.925-1.26 3.535 0 1.54.385 2.66 1.155 3.36.793.7 1.937 1.05 3.43 1.05h2.24V4.965h-2.1Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M33.965 8.395V26H29.8v-7.35h-5.565V26H20.07V8.395h4.165v6.965H29.8V8.395h4.165Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M52.938 30.025h-3.85V26h-9.345v4.025h-3.85V22.71h1.295c.373-.724.665-1.599.875-2.625.233-1.027.408-2.112.525-3.255a44.21 44.21 0 0 0 .28-3.535c.046-1.19.07-2.322.07-3.395V8.395h12.145V22.71h1.855v7.315Zm-6.02-18.34h-4.41v.525a51.6 51.6 0 0 1-.07 2.555 39.922 39.922 0 0 1-.245 2.835c-.094.956-.245 1.89-.455 2.8-.187.887-.42 1.657-.7 2.31h5.88V11.685ZM58.641 18.685c.07 1.47.432 2.567 1.085 3.29.653.723 1.75 1.085 3.29 1.085 1.027 0 2.03-.163 3.01-.49 1.003-.327 1.832-.712 2.485-1.155v3.395c-.607.42-1.4.782-2.38 1.085-.98.303-2.135.455-3.465.455-2.683 0-4.748-.747-6.195-2.24-1.423-1.494-2.135-3.78-2.135-6.86 0-1.33.152-2.555.455-3.675.303-1.12.758-2.088 1.365-2.905a6.242 6.242 0 0 1 2.345-1.925c.957-.467 2.065-.7 3.325-.7 1.05 0 1.995.151 2.835.455.84.303 1.552.793 2.135 1.47.607.676 1.073 1.551 1.4 2.625.327 1.05.49 2.345.49 3.885v2.205H58.641Zm3.115-7.35c-1.003 0-1.762.361-2.275 1.085-.513.723-.793 1.785-.84 3.185h5.81v-.35c0-.56-.047-1.074-.14-1.54-.07-.49-.21-.91-.42-1.26s-.49-.619-.84-.805c-.35-.21-.782-.315-1.295-.315Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M75.641 17.495V26h-4.165V8.395h4.165v8.26l5.88-8.26h4.2l-5.88 8.26L86.528 26h-4.725l-6.16-8.505ZM96.024 26.35c-2.8 0-4.877-.782-6.23-2.345-1.354-1.563-2.03-3.827-2.03-6.79 0-1.33.163-2.555.49-3.675.35-1.143.863-2.112 1.54-2.905a7.096 7.096 0 0 1 2.555-1.89c1.003-.467 2.17-.7 3.5-.7 1.05 0 1.948.081 2.695.245.746.14 1.341.338 1.785.595v3.535c-.42-.257-.992-.502-1.715-.735a7.481 7.481 0 0 0-2.31-.35c-1.377 0-2.427.49-3.15 1.47-.724.957-1.085 2.392-1.085 4.305 0 1.82.326 3.267.98 4.34.653 1.073 1.726 1.61 3.22 1.61.886 0 1.645-.105 2.275-.315a6.695 6.695 0 0 0 1.785-.875v3.395c-1.004.723-2.439 1.085-4.305 1.085ZM126.164 1.675V26h-4.235V4.965h-7.56V26h-4.235V1.675h16.03Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M133.71 10.67c.537-.864 1.202-1.517 1.995-1.96a5.558 5.558 0 0 1 2.695-.665c.98 0 1.879.198 2.695.595.817.396 1.517.991 2.1 1.785.584.77 1.039 1.726 1.365 2.87.327 1.12.49 2.415.49 3.885 0 1.47-.175 2.776-.525 3.92-.326 1.143-.793 2.111-1.4 2.905a5.635 5.635 0 0 1-2.17 1.75c-.84.396-1.761.595-2.765.595-1.843 0-3.301-.805-4.375-2.415v7.14h-4.165V8.395h3.85l.21 2.275Zm3.43 12.39c1.214 0 2.112-.467 2.695-1.4.607-.933.91-2.427.91-4.48 0-2.053-.28-3.535-.84-4.445-.56-.933-1.411-1.4-2.555-1.4-1.166 0-2.041.443-2.625 1.33-.583.887-.886 2.31-.91 4.27v.28c0 2.053.269 3.547.805 4.48.56.91 1.4 1.365 2.52 1.365ZM154.904 8.045c1.143 0 2.193.198 3.15.595a6.4 6.4 0 0 1 2.45 1.785c.677.77 1.202 1.726 1.575 2.87.397 1.12.595 2.415.595 3.885 0 1.47-.198 2.776-.595 3.92-.373 1.143-.898 2.111-1.575 2.905a6.565 6.565 0 0 1-2.45 1.75c-.957.396-2.007.595-3.15.595-1.143 0-2.193-.199-3.15-.595a6.565 6.565 0 0 1-2.45-1.75c-.677-.793-1.213-1.75-1.61-2.87-.373-1.144-.56-2.45-.56-3.92 0-1.47.187-2.777.56-3.92.397-1.143.933-2.1 1.61-2.87a6.4 6.4 0 0 1 2.45-1.785c.957-.397 2.007-.595 3.15-.595Zm0 15.015c1.167 0 2.03-.467 2.59-1.4.583-.933.875-2.427.875-4.48 0-2.053-.292-3.535-.875-4.445-.56-.933-1.423-1.4-2.59-1.4-1.167 0-2.042.466-2.625 1.4-.56.933-.84 2.427-.84 4.48 0 2.053.28 3.547.84 4.48.583.91 1.458 1.365 2.625 1.365Z"
      clipRule="evenodd"
    />
  </svg>
);

export const YandexIntLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="164" height="28" viewBox="0 0 164 28" fill="none" {...props}>
    <path
      fill="#21201F"
      d="m6.9 1.675 4.97 10.185 5.005-10.185h3.955l-7.105 13.93V26H9.49V15.605L2.35 1.675H6.9Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M36.085 21.695c0 1.447.07 2.881.21 4.305h-4.06a7.655 7.655 0 0 1-.21-.665 9.251 9.251 0 0 1-.105-.735h-.175c-.397.42-.91.805-1.54 1.155-.63.326-1.552.49-2.765.49-1.61 0-2.917-.479-3.92-1.435-.98-.957-1.47-2.333-1.47-4.13 0-1.797.735-3.185 2.205-4.165 1.47-1.004 3.803-1.505 7-1.505h.665v-.98c0-.957-.233-1.622-.7-1.995-.467-.397-1.202-.595-2.205-.595a8.05 8.05 0 0 0-1.645.175c-.56.093-1.097.221-1.61.385-.49.163-.957.35-1.4.56-.42.21-.77.42-1.05.63V9.725c.63-.374 1.47-.735 2.52-1.085s2.275-.525 3.675-.525c2.217 0 3.862.443 4.935 1.33 1.097.886 1.645 2.403 1.645 4.55v7.7Zm-4.165-3.71h-.595c-1.797 0-3.08.21-3.85.63-.77.397-1.155 1.097-1.155 2.1 0 .747.21 1.353.63 1.82.443.443 1.108.665 1.995.665.747 0 1.365-.128 1.855-.385.49-.28.863-.619 1.12-1.015v-3.815Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M43.599 26h-4.165V8.395h4.06l.105 1.47h.21c.42-.42 1.003-.817 1.75-1.19.77-.374 1.785-.56 3.045-.56 1.703 0 2.928.408 3.675 1.225.746.793 1.12 2.065 1.12 3.815V26h-4.165V13.575c0-1.307-.712-1.96-2.135-1.96-.747 0-1.424.175-2.03.525a5.872 5.872 0 0 0-1.47 1.225V26Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M67.369 23.725c-1.144 1.75-2.707 2.625-4.69 2.625-.98 0-1.879-.199-2.695-.595a5.79 5.79 0 0 1-2.1-1.75c-.584-.77-1.039-1.715-1.365-2.835-.327-1.12-.49-2.404-.49-3.85 0-1.47.163-2.777.49-3.92.35-1.167.816-2.135 1.4-2.905a6.213 6.213 0 0 1 2.17-1.82c.84-.42 1.761-.63 2.765-.63 1.82 0 3.29.805 4.41 2.415V1.675h4.165V26h-3.85l-.21-2.275Zm-3.465-12.39c-1.19 0-2.089.478-2.695 1.435-.584.933-.875 2.45-.875 4.55 0 2.006.28 3.465.84 4.375.56.91 1.411 1.365 2.555 1.365 1.143 0 2.006-.432 2.59-1.295.583-.887.898-2.31.945-4.27v-.91c-.047-1.82-.35-3.15-.91-3.99-.56-.84-1.377-1.26-2.45-1.26ZM78.517 18.685c.07 1.47.432 2.567 1.085 3.29.653.723 1.75 1.085 3.29 1.085 1.027 0 2.03-.163 3.01-.49 1.004-.327 1.832-.712 2.485-1.155v3.395c-.606.42-1.4.782-2.38 1.085-.98.303-2.135.455-3.465.455-2.683 0-4.748-.747-6.195-2.24-1.423-1.494-2.135-3.78-2.135-6.86 0-1.33.152-2.555.455-3.675.303-1.12.758-2.088 1.365-2.905a6.242 6.242 0 0 1 2.345-1.925c.957-.467 2.065-.7 3.325-.7 1.05 0 1.995.151 2.835.455.84.303 1.552.793 2.135 1.47.607.676 1.074 1.551 1.4 2.625.327 1.05.49 2.345.49 3.885v2.205H78.517Zm3.115-7.35c-1.003 0-1.761.361-2.275 1.085-.513.723-.793 1.785-.84 3.185h5.81v-.35a7.87 7.87 0 0 0-.14-1.54c-.07-.49-.21-.91-.42-1.26s-.49-.619-.84-.805c-.35-.21-.782-.315-1.295-.315Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M97.548 20.085 94.013 26h-4.235l5.53-9.135-5.32-8.47h4.69l3.325 5.32 3.045-5.32h4.165l-4.97 8.54 5.74 9.065h-4.69l-3.745-5.915Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M121.237 1.675c2.894 0 5.122.665 6.685 1.995 1.564 1.33 2.345 3.231 2.345 5.705 0 1.353-.221 2.531-.665 3.535a6.364 6.364 0 0 1-1.82 2.45c-.77.653-1.715 1.143-2.835 1.47-1.12.327-2.368.49-3.745.49h-2.065V26h-4.235V1.675h6.335Zm0 12.355c1.47 0 2.625-.373 3.465-1.12.84-.747 1.26-1.925 1.26-3.535 0-1.54-.396-2.66-1.19-3.36-.77-.7-1.901-1.05-3.395-1.05h-2.24v9.065h2.1Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M136.875 26h-4.165V8.395h4.06l.105 3.5h.105c.49-1.167 1.202-2.089 2.135-2.765.933-.677 1.995-1.05 3.185-1.12v3.92c-1.283-.07-2.368.163-3.255.7-.863.513-1.587 1.306-2.17 2.38V26Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M151.64 8.045c1.144 0 2.194.198 3.15.595a6.392 6.392 0 0 1 2.45 1.785c.677.77 1.202 1.726 1.575 2.87.397 1.12.595 2.415.595 3.885 0 1.47-.198 2.776-.595 3.92-.373 1.143-.898 2.111-1.575 2.905a6.557 6.557 0 0 1-2.45 1.75c-.956.396-2.006.595-3.15.595-1.143 0-2.193-.199-3.15-.595a6.573 6.573 0 0 1-2.45-1.75c-.676-.793-1.213-1.75-1.61-2.87-.373-1.144-.56-2.45-.56-3.92 0-1.47.187-2.777.56-3.92.397-1.143.934-2.1 1.61-2.87a6.408 6.408 0 0 1 2.45-1.785c.957-.397 2.007-.595 3.15-.595Zm0 15.015c1.167 0 2.03-.467 2.59-1.4.584-.933.875-2.427.875-4.48 0-2.053-.291-3.535-.875-4.445-.56-.933-1.423-1.4-2.59-1.4-1.166 0-2.041.466-2.625 1.4-.56.933-.84 2.427-.84 4.48 0 2.053.28 3.547.84 4.48.584.91 1.459 1.365 2.625 1.365Z"
      clipRule="evenodd"
    />
  </svg>
);

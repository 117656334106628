/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useCallback, useMemo, useRef, type ReactNode } from "react";
import { usePress } from "react-aria";
import ReactMarkdown from "react-markdown";
import { cssFns, useFontFace } from "@superweb/css";
import { Button, useIsMobile, useTypo, useUiColors, icons } from "@superweb/ui";
import { useLocale } from "@superweb/intl";
import { useMessage } from "#intl";
import { useSlides } from "./context";
import { ProgressBars } from "./progress-bars";
import type { SlideProps } from "./context";

const COUNTER_INTERVAL = 300;

const arrowPathMap = {
  ArrowLeftDark: new URL("images/ArrowLeftDark.svg", import.meta.url).href,
  ArrowLeftLight: new URL("images/ArrowLeftLight.svg", import.meta.url).href,
  ArrowRightDark: new URL("images/ArrowRightDark.svg", import.meta.url).href,
  ArrowRightLight: new URL("images/ArrowRightLight.svg", import.meta.url).href,
};

const Slide = ({
  content,
  backgroundImage,
  backgroundMode = "full",
  image,
  text,
  textAlign,
  titleText,
  titleColor,
  textColor,
  primaryButtonView,
  secondaryButtonView,
  primaryButtonText,
  secondaryButtonText,
  illustrationContent,
  __render_custom_button,
  onPressPrimary,
  onPressSecondary,
}: SlideProps & {
  content?: ReactNode;
  prohibitTriggerNavigation?: boolean;
  textAlign?: "start" | "end";
  onPressPrimary?: () => void;
  onPressSecondary?: () => void;
  __render_custom_button?: () => ReactNode;
}) => {
  const { brand } = useSlides();
  const isMobile = useIsMobile();
  const uiColors = useUiColors();
  const typo = useTypo();
  const fontFace = useFontFace();

  const illustration = (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "0",
        minWidth: "0",
        ...(image &&
          (isMobile
            ? {
                paddingBlockEnd: textAlign === "start" ? "108px" : undefined,
                paddingBlockStart: textAlign !== "start" ? "59px" : undefined,
              }
            : {
                paddingBlockStart: textAlign === "start" ? "41px" : undefined,
              })),
        ...(backgroundImage && {
          backgroundImage: `url(${backgroundImage})`,
          ...(isMobile
            ? cssFns.margin("-40px", "0")
            : cssFns.margin("0", "-24px")),
          ...(backgroundMode === "full" && {
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
          }),
        }),
      }}
    >
      {illustrationContent
        ? illustrationContent
        : image && (
            <img
              src={image}
              css={{
                objectFit: "contain",
                maxHeight: "552px",
                height: "100%",
                maxWidth: "552px",
                width: "100%",
              }}
            />
          )}
    </div>
  );

  const actions = __render_custom_button ? (
    <div
      css={{
        display: "grid",
        flexShrink: "0",
        gridTemplateColumns: "1fr",
        columnGap: "4px",
        ...cssFns.padding("4px", "8px"),
        paddingBlockEnd: "8px",
        position: "absolute",
        bottom: "0",
        right: "0",
        left: "0",
        zIndex: "1",
      }}
    >
      {__render_custom_button()}
    </div>
  ) : primaryButtonText ? (
    <div
      css={{
        display: "grid",
        flexShrink: "0",
        gridTemplateColumns: secondaryButtonText ? "repeat(2, 1fr)" : "1fr",
        columnGap: "4px",
        ...cssFns.padding("4px", "8px"),
        paddingBlockEnd: "8px",
        position: "absolute",
        bottom: "0",
        right: "0",
        left: "0",
        zIndex: "1",
      }}
    >
      {secondaryButtonText && (
        <Button
          view={
            (isMobile && textAlign === "end") || !isMobile
              ? "default"
              : secondaryButtonView
          }
          text={secondaryButtonText}
          onPress={onPressSecondary}
          size="l"
        />
      )}
      <Button
        view={
          (isMobile && textAlign === "end") || !isMobile
            ? "action"
            : primaryButtonView
        }
        text={primaryButtonText}
        onPress={onPressPrimary}
        size="l"
      />
    </div>
  ) : null;

  const article = (text || titleText || content) && (
    <div
      css={{
        backgroundColor: uiColors.everFront,
        ...cssFns.overflow("hidden"),
        ...(isMobile
          ? textAlign === "start"
            ? {
                paddingBlockStart: "32px",
                borderBottomRightRadius: "40px",
                borderBottomLeftRadius: "40px",
              }
            : {
                paddingBlockEnd: primaryButtonText ? "68px" : undefined,
                borderTopRightRadius: "40px",
                borderTopLeftRadius: "40px",
              }
          : cssFns.border({ radius: "24px" })),
        position: "relative",
        display: "flex",
        ...(isMobile
          ? undefined
          : { justifyContent: "start", alignItems: "center" }),
      }}
    >
      {content ?? (
        <div
          css={{
            color: titleColor || textColor,
            ...cssFns.padding(isMobile ? "32px" : "56px"),
            ...cssFns.overflow("hidden"),
          }}
        >
          <div
            css={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
              ...cssFns.overflow("hidden"),
            }}
          >
            {titleText && (
              <h3
                css={{
                  ...(brand === "yango"
                    ? {
                        fontFamily: `${fontFace({ src: `url(${new URL("fonts/YangoHeadline-Black", import.meta.url).href})`, fontWeight: "900", fontDisplay: "swap" })}, sans-serif`,
                        fontFeatureSettings: "'pnum', 'lnum'",
                        fontWeight: "900",
                        fontSize: "46px",
                        lineHeight: "44px",
                        letterSpacing: "0",
                      }
                    : typo({
                        level: "header",
                        weight: "bold",
                        density: "normal",
                      })),
                  wordWrap: "break-word",
                  ...cssFns.margin("0"),
                  maxHeight: "276px",
                  ...cssFns.overflow("hidden"),
                  paddingBottom: "2px",
                }}
              >
                {titleText}
              </h3>
            )}
            {text && (
              <div
                css={{
                  ...typo({
                    level: "body1",
                    weight: "regular",
                    density: "loose",
                  }),
                  maxHeight: "260px",
                  ...cssFns.overflow("hidden"),
                }}
              >
                <ReactMarkdown
                  children={text}
                  components={{
                    ul: ({ children }) => (
                      <ul
                        css={{
                          listStylePosition: "outside",
                          listStyleType: "revert",
                          paddingInlineStart: "22px",
                          ...cssFns.margin("2px", "0"),
                        }}
                      >
                        {children}
                      </ul>
                    ),
                    p: ({ children }) => (
                      <p
                        css={{
                          ...cssFns.margin("4px", "0"),
                        }}
                      >
                        {children}
                      </p>
                    ),
                  }}
                  allowedElements={["ul", "li", "p"]}
                  unwrapDisallowed
                />
              </div>
            )}
          </div>
          {!isMobile && actions}
        </div>
      )}
    </div>
  );

  return (
    <div
      css={{
        display: "grid",
        height: "100%",
        ...(isMobile
          ? {
              gridTemplateRows: textAlign === "start" ? "auto 1fr" : "1fr auto",
            }
          : {
              gridTemplateColumns: "1fr 1fr",
            }),
      }}
    >
      {textAlign === "start" ? (
        <>
          {article}
          {illustration}
        </>
      ) : (
        <>
          {illustration}
          {article}
        </>
      )}
      {isMobile && actions}
    </div>
  );
};

export const Slides = ({
  isClose,
  onCloseStory,
}: {
  isClose?: boolean;
  onCloseStory?: () => void;
}) => {
  const {
    textInfo: { direction },
  } = useLocale();
  const message = useMessage();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();
  const {
    nextSlide,
    previousSlide,
    currentSlideIndex,
    isPaused,
    holdSlide,
    slides,
    onActionSlide,
  } = useSlides();

  const slide = useMemo(
    () => slides[currentSlideIndex],
    [currentSlideIndex, slides],
  );

  const { backgroundColor, textAlign, fillerColor } = useMemo(
    () => ({
      backgroundColor: slide?.backgroundColor,
      textAlign: (slide?.textPosition === "top" ? "start" : "end") as
        | "start"
        | "end",
      fillerColor: slide?.progressFillerColor,
    }),
    [slide],
  );

  const { leftIconPathname, rightIconPathname } = useMemo(
    () =>
      textAlign === "start"
        ? direction === "ltr"
          ? {
              leftIconPathname: arrowPathMap.ArrowLeftDark,
              rightIconPathname: arrowPathMap.ArrowRightLight,
            }
          : {
              leftIconPathname: arrowPathMap.ArrowRightDark,
              rightIconPathname: arrowPathMap.ArrowLeftLight,
            }
        : direction === "ltr"
          ? {
              leftIconPathname: arrowPathMap.ArrowLeftLight,
              rightIconPathname: arrowPathMap.ArrowRightDark,
            }
          : {
              leftIconPathname: arrowPathMap.ArrowRightLight,
              rightIconPathname: arrowPathMap.ArrowLeftDark,
            },
    [textAlign, direction],
  );

  const handleNextSlide = useCallback(() => nextSlide("timer"), [nextSlide]);

  const timeRef = useRef(0);

  const handleSlidePointerDown = () => {
    holdSlide(true);
    timeRef.current = Date.now();
  };
  const handleSlidePointerUp = (cb: () => void) => {
    if (Date.now() - timeRef.current < COUNTER_INTERVAL) {
      cb();
    }
    holdSlide(false);
  };

  const { pressProps: pressLeftProps } = usePress({
    onPressStart: () => handleSlidePointerDown(),
    onPressEnd: () => handleSlidePointerUp(() => previousSlide("click")),
  });

  const { pressProps: pressRightProps } = usePress({
    onPressStart: () => handleSlidePointerDown(),
    onPressEnd: () => handleSlidePointerUp(() => nextSlide("click")),
  });

  if (!slide) return null;

  return (
    <div
      css={{
        height: "100%",
        backgroundColor,
        ...(isMobile ? undefined : cssFns.border({ radius: "24px" })),
        ...cssFns.overflow("hidden"),
      }}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          css={{
            display: index !== currentSlideIndex ? "none" : undefined,
            height: "100%",
          }}
        >
          <Slide
            {...slide}
            textAlign={textAlign}
            onPressPrimary={() => onActionSlide?.("primary")}
            onPressSecondary={() => onActionSlide?.("secondary")}
          />
        </div>
      ))}
      <div
        css={{
          display: "flex",
          position: "absolute",
          top: "0px",
          ...(direction === "rtl" ? { left: "0px" } : { right: "0px" }),
          ...(isMobile
            ? {
                flexDirection: "column",
                alignItems: "end",
                width: "100%",
                ...cssFns.padding("16px"),
                paddingInlineEnd: "0",
              }
            : {
                width: "50%",
                paddingInlineStart: "32px",
                paddingInlineEnd: "16px",
                paddingBlockStart: "16px",
                alignItems: "center",
                columnGap: "16px",
              }),
          boxSizing: "border-box",
        }}
      >
        <div
          css={{
            width: "100%",
            ...(isMobile
              ? { paddingInlineEnd: "16px", boxSizing: "border-box" }
              : undefined),
          }}
        >
          <ProgressBars
            slides={slides}
            currentSlideIndex={currentSlideIndex}
            isPaused={isPaused}
            onNextSlide={handleNextSlide}
          />
        </div>
        {isClose && (
          <div css={{ zIndex: "1" }}>
            <Button
              icon={(props: { className?: string }) => (
                <icons.CrossL
                  {...props}
                  color={
                    (isMobile ? textAlign === "start" : textAlign === "end")
                      ? uiColors.everBack
                      : fillerColor
                  }
                />
              )}
              onPress={onCloseStory}
              ariaLabel={message({
                id: "c3442851-b2cd-47d2-b2af-e4e7f8c7f4b4",
                context:
                  "Stories view mode. Close story slide button label for screen readers",
                default: "Close story",
              })}
              view="ghost"
              size={isMobile ? "l" : "m"}
            />
          </div>
        )}
      </div>
      {slide.prohibitTriggerNavigation ? undefined : (
        <>
          <div
            {...pressLeftProps}
            css={{
              cursor: isMobile ? undefined : `url("${leftIconPathname}"), auto`,
              position: "absolute",
              insetBlockStart: "0",
              insetBlockEnd: "0",
              insetInlineStart: "0",
              insetInlineEnd: "50%",
              height: "100%",
            }}
          />
          <div
            {...pressRightProps}
            css={{
              cursor: isMobile
                ? undefined
                : `url("${rightIconPathname}"), auto`,
              position: "absolute",
              insetBlockStart: "0",
              insetBlockEnd: "0",
              insetInlineStart: "50%",
              insetInlineEnd: "0",
              height: "100%",
            }}
          />
        </>
      )}
    </div>
  );
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { memo, useMemo } from "react";
import { createPortal } from "react-dom";

import { cssFns, useKeyframes } from "@superweb/css";

const random = (min: number, max: number) => {
  return min + Math.floor(Math.random() * (max - min) + 1);
};

export const Snow = memo(() => {
  const keyframes = useKeyframes();

  const snow = useMemo(() => {
    const snowflakes = [];
    const dimensions = { width: 100, height: 100 };

    for (let j = 1; j <= 50; j++) {
      const x = (random(1, 100) * dimensions.width) / 100;
      const offset = (random(-10, 10) * dimensions.width) / 100;

      const time = Math.round(random(30, 100));
      const yStart = (time * dimensions.height) / 100;
      const yEnd = dimensions.height;
      const scale = random(1, 10000) * 0.0001;
      const size = random(10, 20) + "px";

      snowflakes.push(
        <div
          data-snow-id={j}
          key={j}
          css={{
            pointerEvents: "none",
            opacity: String(random(0, 1)),
            transform: `translate(${x}vw, -10px) scale(${scale})`,
            animationName: keyframes({
              [time]: {
                transform: `translate(${x + offset}vw, ${yStart}vh) scale(${scale})`,
              },
              100: {
                transform: `translate(${x + offset / 2}vw, ${yEnd}vh) scale(${scale})`,
              },
            }),
            animationDuration: String(random(10, 30)) + "s",
            animationDelay: String(random(0, 30) * -1) + "s",
            animationIterationCount: "infinite",
            animationTimingFunction: "linear",
            position: "absolute",
            width: size,
            height: size,
            backgroundColor: "#E9EAEA",
            ...cssFns.border({ radius: "50%" }),
            boxShadow: `0px 0px ${random(16, 32)}px  ${random(10, 20)}px rgba(185, 185, 185, 0.4)`,
            filter: "blur(4px)",
            zIndex: "1",
          }}
        />,
      );
    }

    return snowflakes;
  }, [keyframes]);

  return createPortal(
    <div
      css={{
        position: "absolute",
        ...cssFns.inset("0"),
      }}
    >
      {snow}
    </div>,
    document.body,
  );
});

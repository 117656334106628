/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { getCountryCallingCode, type CountryCode } from "libphonenumber-js";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams } from "react-router-dom";

import { cssFns } from "@superweb/css";
import { icons, useIsMobile, useTypo, useUiColors } from "@superweb/ui";
import { useFlag } from "@superweb/flags";

import type { UseV1ParkInfoGetResult } from "@fleet/api/fleet-forms";

import { Message, useMessage } from "#internal/intl";
import { LinkButton, Offer, PageHeader } from "#internal/ui";
import { useLogAction } from "#internal/analytics";
import { useErrorMessages } from "#internal/utils";
import { useSubmitErrorMessages } from "#internal/utils/validation";

import { DriverLicenseDetailForm } from "./driver-license-details-form";
import { VehicleDetailsForm } from "./vehicle-details-form";
import { DownloadAppPage } from "./download-app-page";
import { RegisterForm } from "./register-form";

export type ParkInfoData = Extract<
  UseV1ParkInfoGetResult,
  {
    status: 200;
  }
>["data"];

type Stage = "main" | "vehicle" | "license" | "download_app";

export const Form = ({
  parkInfo,
  refId,
  isIframe,
  isDelivery,
}: {
  parkInfo: ParkInfoData;
  refId: string;
  isIframe: boolean;
  isDelivery: boolean;
}) => {
  const isMobile = useIsMobile();
  const uiColors = useUiColors();
  const message = useMessage();
  const errorMessages = useErrorMessages();
  const [searchParams] = useSearchParams();
  const submitErrorMessages = useSubmitErrorMessages();
  const flag = useFlag();

  const hasVehicleStep = searchParams.get("skip_vehicle") !== "1";
  const hasLicenseStep = searchParams.get("skip_license") !== "1";

  const countryCode = getCountryCallingCode(
    parkInfo.country_code as CountryCode,
  );

  const stepsCount =
    hasVehicleStep && hasLicenseStep
      ? 3
      : hasLicenseStep || hasVehicleStep
        ? 2
        : 1;

  const [step, setStep] = useState({
    total: stepsCount,
    current: 1,
  });
  const [hasSubmit, setSubmit] = useState(false);
  const sessionId = useMemo(() => uuidv4(), []);
  const [formErrorMessage, setFormErrorMessage] = useState<string>();

  const onError = (code?: string) => {
    if (
      code &&
      flag({
        id: "db9b6a70-5e69-4801-9fae-e5084c6411d8",
        description: "Enable error messages",
      })
    ) {
      setFormErrorMessage(submitErrorMessages[code] ?? errorMessages.default);
    } else {
      setFormErrorMessage(errorMessages.default);
    }
  };

  const onSuccess = () => {
    setFormErrorMessage(undefined);
  };

  const formErrorContent = formErrorMessage ? (
    <div
      role="alert"
      css={{
        color: uiColors.error,
      }}
    >
      {formErrorMessage}
    </div>
  ) : undefined;

  const getForm =
    [
      () => (
        <RegisterForm
          onSubmit={() => {
            if (step.current === step.total) {
              setSubmit(true);
            } else {
              setStep((prev) => ({
                ...prev,
                current: Math.min(prev.total, ++prev.current),
              }));
            }
          }}
          countryCode={countryCode}
          refId={refId}
          sessionId={sessionId}
          onError={onError}
          onSuccess={onSuccess}
          parkInfo={parkInfo}
          bottomSheetContent={formErrorContent}
        />
      ),
      ...(hasVehicleStep
        ? [
            () => (
              <VehicleDetailsForm
                onSubmit={() => {
                  if (step.current === step.total) {
                    setSubmit(true);
                  } else {
                    setStep((prev) => ({
                      ...prev,
                      current: Math.min(prev.total, ++prev.current),
                    }));
                  }
                }}
                sessionId={sessionId}
                refId={refId}
                vehicleTypes={parkInfo.vehicle_types}
                onError={onError}
                onSuccess={onSuccess}
                bottomSheetContent={formErrorContent}
              />
            ),
          ]
        : []),
      ...(hasLicenseStep
        ? [
            () => (
              <DriverLicenseDetailForm
                onSumbit={() => setSubmit(true)}
                sessionId={sessionId}
                refId={refId}
                onError={onError}
                onSuccess={onSuccess}
                licenseTemplates={parkInfo.license_number_templates}
                bottomSheetContent={formErrorContent}
              />
            ),
          ]
        : []),
    ][step.current - 1] ?? null;

  const stage =
    ["main", "vehicle", "license", "download_app"][step.current - 1] ?? null;

  const title = [
    message({
      id: "ae168b5e-e7f1-4400-9287-b6655bffcd96",
      context: "Yango forms. Header title",
      default: "Contractor registration",
    }),
    message({
      id: "ae168b5e-e7f1-4400-9287-b6655bffcd95",
      context: "Yango forms. Vehicle details form. Header title",
      default: "Add vehicle details",
    }),
    message({
      id: "0291ef6c-771c-453a-b8fd-f35500860dfd",
      context: "Yango forms. Driver details form. Header title",
      default: "Add driver's license details",
    }),
  ][step.current - 1];

  const yangoBrand = parkInfo.brand === "yango";

  const successMessage = yangoBrand
    ? message({
        id: "9e5c505a-477d-4811-8e70-3a6699695eae",
        context: "Yango forms. Download app page. Title",
        default: "Registration complete!",
      })
    : message({
        id: "3aa53184-d742-44b0-82aa-c14b64fb5bed",
        context: "Yandex forms. Download app page. Title",
        default: "Request submitted",
      });

  const logoHeight = isMobile ? 60 : 80;

  return (
    <>
      <Helmet>
        <title>{hasSubmit ? successMessage : title}</title>
      </Helmet>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          ...(isMobile
            ? { rowGap: "8px", ...cssFns.padding("10px") }
            : { rowGap: "20px", ...cssFns.padding("16px") }),
          backgroundColor: uiColors.backgroundMinor,
        }}
      >
        {!isIframe && (
          <PageHeader
            logoHeight={logoHeight}
            partnerName={parkInfo.partner_name}
          />
        )}
        <main
          css={{
            display: "grid",
            justifyItems: "center",
            height: `calc(100% - ${logoHeight}px)`,
          }}
        >
          <div
            css={{
              height: "100%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              rowGap: "16px",
              maxWidth: "764px",
              width: "100%",
            }}
          >
            <div
              css={{
                display: "grid",
                gridTemplateRows:
                  step.current === 1 && parkInfo.promo_text
                    ? "auto 1fr"
                    : "1fr",
                height: "100%",
                position: "relative",
                isolation: "isolate",
                boxSizing: "border-box",
                rowGap: "16px",
              }}
            >
              {step.current === 1 && !isIframe && parkInfo.promo_text && (
                <Offer
                  offerText={parkInfo.promo_text}
                  isDelivery={isDelivery}
                />
              )}
              <div
                css={{
                  display: "grid",
                  backgroundColor: uiColors.background,
                  ...cssFns.border({ radius: "26px" }),
                }}
              >
                {hasSubmit ? (
                  <DownloadAppPage
                    androidHref={parkInfo.link_to_pro.android_app}
                    iosHref={parkInfo.link_to_pro.ios_app}
                    successMessage={successMessage}
                    isDelivery={isDelivery}
                  />
                ) : (
                  <div
                    css={{
                      display: "grid",
                      gridTemplateRows: title ? "auto 1fr" : "1fr",
                    }}
                  >
                    {title ? (
                      <FormHeader step={step} title={title} />
                    ) : undefined}
                    {getForm?.()}
                  </div>
                )}
              </div>
            </div>
            {!isIframe &&
              (parkInfo.phone || parkInfo.message_link) &&
              stage && (
                <ContactDetails
                  stage={stage as Stage}
                  phone={parkInfo.phone}
                  messageLink={parkInfo.message_link}
                />
              )}
          </div>
        </main>
      </div>
    </>
  );
};

const FormHeader = ({
  title,
  step,
}: {
  title: string;
  step: { current: number; total: number };
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          ...cssFns.padding("4px"),
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            rowGap: "18px",
            ...cssFns.padding("14px", "12px"),
          }}
        >
          <span
            css={{
              ...typo({
                level: "body1",
                weight: "medium",
                density: "tight",
              }),
              color: uiColors.text,
            }}
          >
            {title}
          </span>
        </div>
      </div>
      {step.total > 1 && (
        <div
          css={{
            marginBlockEnd: "4px",
            ...cssFns.padding("0", "20px"),
          }}
        >
          <StepIndicator {...step} />
        </div>
      )}
    </div>
  );
};

const StepIndicator = ({
  current,
  total,
}: {
  current: number;
  total: number;
}) => {
  const uiColors = useUiColors();

  return (
    <div
      css={{
        width: "100%",
        height: "4px",
        columnGap: "8px",
        display: "grid",
        gridTemplateColumns: `repeat(${total}, 1fr)`,
      }}
    >
      {Array.from({ length: total }, (_, index) => index).map((index) => (
        <div
          key={index}
          css={{
            ...cssFns.border({ radius: "20px" }),
            backgroundColor:
              current - 1 === index
                ? uiColors.controlMain
                : uiColors.controlMinor,
          }}
        />
      ))}
    </div>
  );
};

const ContactDetails = ({
  phone,
  messageLink,
  stage,
}: {
  phone?: string;
  messageLink?: string;
  stage: Stage;
}) => {
  const message = useMessage();
  const uiColors = useUiColors();
  const typo = useTypo();
  const isMobile = useIsMobile();
  const logAction = useLogAction("contact");

  const phoneNumber = phone?.slice(1);

  return (
    <div
      css={{
        display: "grid",
        width: "100%",
        ...(isMobile
          ? { gridTemplateRows: "auto 1fr", rowGap: "4px" }
          : { gridTemplateColumns: "1fr 1fr", columnGap: "20px" }),
      }}
    >
      <div
        css={{
          ...typo({
            level: isMobile ? "body2" : "body1",
            weight: "medium",
            density: "tight",
          }),
          ...(isMobile
            ? {
                alignSelf: "center",
                justifySelf: "center",
              }
            : {
                maxWidth: "250px",
                justifySelf: "end",
                paddingInlineEnd: "22px",
                paddingBlockStart: "6px",
                display: "flex",
                flexDirection: "column",
              }),
        }}
      >
        {isMobile ? (
          <Message
            id="395971b0-9e94-46f7-84ca-775f7b441ca5"
            context="Yango form. Contact details. Contact us"
            default="Got questions? Contact us!"
          />
        ) : (
          <>
            <span>
              <Message
                id="395971b0-9e94-46f7-84ca-775f7b441ca6"
                context="Yango form. Contact details. Contact us"
                default="Got questions?"
              />
            </span>
            <span>
              <Message
                id="395971b0-9e94-46f7-84ca-775f7b441ca7"
                context="Yango form. Contact details. Contact us"
                default="Contact us!"
              />
            </span>
          </>
        )}
      </div>
      <div
        css={{
          display: "grid",
          ...(isMobile
            ? {
                justifySelf: "center",
                alignSelf: "center",
                gridTemplateColumns: "auto auto",
              }
            : {
                justifySelf: "start",
                gridTemplateColumns: "min-content",
              }),
        }}
      >
        {phone && (
          <LinkButton
            link={{ href: `tel:${phoneNumber}` }}
            icon={(props) => <icons.Call {...props} />}
            text={phone}
            onPress={() =>
              logAction("phone_click", { onlyYM: true, params: { stage } })
            }
          />
        )}
        {messageLink && (
          <LinkButton
            link={{
              href: messageLink,
              external: true,
            }}
            icon={(props) => (
              <icons.WhatsAppL
                {...props}
                color={uiColors.categorical.category1}
              />
            )}
            text={message({
              id: "2efdf9d3-cfb8-4984-9b0f-77611038879f",
              context: "Yango forms. Contactor deatails. Whats app",
              default: "Ask on WhatsApp",
            })}
            onPress={() =>
              logAction("whatsapp_click", { onlyYM: true, params: { stage } })
            }
          />
        )}
      </div>
    </div>
  );
};

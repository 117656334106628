/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef } from "react";
import ReactMarkdown from "react-markdown";

import { cssFns } from "@superweb/css";
import { useIsMobile, useTypo, useUiColors } from "@superweb/ui";

import { useMessage } from "#internal/intl";

import YandexOfferAvif from "../images/yandex-offer.avif";
import YandexOfferPng from "../images/yandex-offer.png";
import YandexOfferWebp from "../images/yandex-offer.webp";
import YangoOfferAvif from "../images/offer.avif";
import YangoOfferPng from "../images/offer.png";
import YangoOfferWebp from "../images/offer.webp";
import YandexDeliveryOfferAvif from "../images/yandex-delivery-offer.avif";
import YandexDeliveryOfferPng from "../images/yandex-delivery-offer.png";
import YandexDeliveryOfferWebp from "../images/yandex-delivery-offer.webp";
import YangoDeliveryOfferAvif from "../images/yango-delivery-offer.avif";
import YangoDeliveryOfferPng from "../images/yango-delivery-offer.png";
import YangoDeliveryOfferWebp from "../images/yango-delivery-offer.webp";
import { useBrand } from "../router";

export const Offer = ({
  offerText,
  isDelivery,
}: {
  offerText: string;
  isDelivery: boolean;
}) => {
  const message = useMessage();
  const uiColors = useUiColors();
  const typo = useTypo();
  const ref = useRef(null);
  const isMobile = useIsMobile();
  const brand = useBrand();

  const yangoBrand = brand === "yango";

  const imageSrc = yangoBrand
    ? isDelivery
      ? {
          avif: YangoDeliveryOfferAvif,
          png: YangoDeliveryOfferPng,
          webp: YangoDeliveryOfferWebp,
        }
      : {
          avif: YangoOfferAvif,
          png: YangoOfferPng,
          webp: YangoOfferWebp,
        }
    : isDelivery
      ? {
          avif: YandexDeliveryOfferAvif,
          png: YandexDeliveryOfferPng,
          webp: YandexDeliveryOfferWebp,
        }
      : {
          avif: YandexOfferAvif,
          png: YandexOfferPng,
          webp: YandexOfferWebp,
        };

  return (
    <div
      ref={ref}
      css={{
        display: "grid",
        gridTemplateColumns: isMobile ? undefined : "auto auto",
        backgroundColor: uiColors.controlMain,
        ...cssFns.border({ radius: "20px" }),
        ...cssFns.padding("16px"),
      }}
    >
      <span
        css={{
          display: "flex",
          flexDirection: "column",
          color: yangoBrand ? uiColors.everFront : uiColors.textOnControlMain,
          ...typo({
            level: isMobile ? "body1" : "title4",
            weight: "medium",
            density: "normal",
          }),
        }}
      >
        <ReactMarkdown
          children={offerText.replace(/([^\n])\n([^\n])/g, "$1\n\n$2")}
          components={{
            ul: ({ children }) => (
              <ul
                css={{
                  paddingInlineStart: "30px",
                  ...cssFns.margin("0"),
                }}
              >
                {children}
              </ul>
            ),
            p: ({ children }) => <p css={cssFns.margin("0")}>{children}</p>,
          }}
          allowedElements={["ul", "li", "p"]}
          unwrapDisallowed
        />
      </span>
      {isMobile ? null : (
        <div
          css={{
            ...cssFns.overflow("hidden"),
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <picture>
            <source src={imageSrc.avif} type="image/avif" />
            <source src={imageSrc.webp} type="image/webp" />
            <img
              css={{
                display: "flex",
                maxWidth: "100%",
                transform: "translateZ(0)",
                objectFit: "contain",
                objectPosition: "center",
                width: "296px",
                height: "132px",
              }}
              width={1161}
              height={521}
              src={imageSrc.png}
              alt={message({
                id: "7e347b54-2aaf-4ba5-bed5-89f34e88a4c3",
                context: "Yango forms. Offer. Image",
                default: "Offer",
              })}
            />
          </picture>
        </div>
      )}
    </div>
  );
};

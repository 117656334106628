/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Helmet } from "react-helmet-async";

import { cssFns, useCss } from "@superweb/css";
import { icons, useIsMobile, useTypo, useUiColors } from "@superweb/ui";

import type { UseV1ParkInfoGetResult } from "@fleet/api/fleet-forms";

import { Message, useMessage } from "#internal/intl";
import { LinkButton, PageHeader } from "#internal/ui";

import info from "../images/info.png";

type ParkInfoData = Extract<
  UseV1ParkInfoGetResult,
  {
    status: 410;
  }
>["data"];

export const ArchivePage = ({
  parkInfo,
  isIframe,
}: {
  parkInfo: ParkInfoData;
  isIframe: boolean;
}) => {
  const css = useCss();
  const isMobile = useIsMobile();
  const uiColors = useUiColors();
  const typo = useTypo();
  const message = useMessage();

  const title = message({
    id: "a166fe31-d24b-484a-b33e-1771686a4aed",
    context: "Fleet forms. Archive link. Page name",
    default: "Archived link",
  });

  const logoHeight = isMobile ? 60 : 80;

  const phone = parkInfo.phone;
  const messageLink = parkInfo.messenger_link;
  const phoneNumber = phone?.slice(1);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          ...(isMobile
            ? { rowGap: "8px", ...cssFns.padding("10px") }
            : { rowGap: "20px", ...cssFns.padding("16px") }),
          backgroundColor: uiColors.backgroundMinor,
        }}
      >
        {!isIframe && (
          <PageHeader
            logoHeight={logoHeight}
            partnerName={parkInfo.partner_name}
          />
        )}
        <main
          css={{
            display: "grid",
            justifyItems: "center",
            height: `calc(100% - ${logoHeight}px)`,
          }}
        >
          <div
            css={{
              height: "100%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              rowGap: "16px",
              maxWidth: "764px",
              width: "100%",
            }}
          >
            <div
              css={{
                display: "grid",
                gridTemplateRows: "1fr",
                height: "100%",
                position: "relative",
                isolation: "isolate",
                boxSizing: "border-box",
                rowGap: "16px",
              }}
            >
              <div
                css={{
                  display: "grid",
                  backgroundColor: uiColors.background,
                  ...cssFns.border({ radius: "26px" }),
                  ...typo({
                    level: "body2",
                    weight: "regular",
                    density: "tight",
                  }),
                  color: uiColors.text,
                }}
              >
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    rowGap: "20px",
                    ...cssFns.margin("auto"),
                    textAlign: "center",
                    ...cssFns.padding("16px"),
                  }}
                >
                  <img
                    src={info}
                    css={{ height: isMobile ? "185px" : "200px" }}
                  />
                  <div
                    css={{
                      ...typo({
                        level: "title4",
                        weight: "medium",
                        density: "tight",
                      }),
                    }}
                  >
                    <Message
                      id="7388ffc1-dbd2-4d46-822f-a7969be5e79f"
                      context="Fleet forms. Archive link. Title"
                      default="Link no longer valid"
                    />
                  </div>
                  {parkInfo.marketing_offer}
                  <div
                    css={{
                      ...typo({
                        level: "body1",
                        weight: "medium",
                        density: "normal",
                      }),
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Message
                      id="c6fddcc7-6af8-4a48-a1e6-5edc3c6948e3"
                      context="Fleet forms. Archive link. Contacts"
                      default="Questions? Contact us!"
                    />
                    {phone && (
                      <LinkButton
                        link={{ href: `tel:${phoneNumber}` }}
                        icon={(props) => <icons.Phone {...props} />}
                        text={phone}
                      />
                    )}
                    {messageLink && (
                      <LinkButton
                        link={{
                          href: messageLink,
                          external: true,
                        }}
                        icon={(props) => (
                          <icons.WhatsAppL
                            {...props}
                            color={uiColors.categorical.category1}
                          />
                        )}
                        text={message({
                          id: "2efdf9d3-cfb8-4984-9b0f-77611048879f",
                          context: "Yango forms. Contactor deatails. Whats app",
                          default: "Ask in WhatsApp",
                        })}
                      />
                    )}
                    {parkInfo.office_address?.address && (
                      <div
                        css={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "6px",
                          alignItems: "center",
                          ...typo({
                            level: "body2",
                            weight: "regular",
                            density: "tight",
                          }),
                        }}
                      >
                        <div css={{ width: "20px" }}>
                          <icons.POIPinOutline
                            className={css({
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "stretch",
                              justifyItems: "stretch",
                            })}
                          />
                        </div>
                        {parkInfo.office_address.address}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

import {
  createApi,
  type ApiRequest as BaseApiRequest,
  type ApiResponse as BaseApiResponse,
} from "@superweb/api";
import type { Api } from "./generated";

export const {
  useAction,
  useCursorQuery,
  useOnDemandCursorQuery,
  useOnDemandQuery,
  useQuery,
} = createApi<Api>({ baseUrl: "/api" });

export type ApiRequest<
  Url extends keyof Api,
  Method extends keyof Api[Url],
> = BaseApiRequest<Api, Url, Method>;

export type ApiResponse<
  Url extends keyof Api,
  Method extends keyof Api[Url],
> = BaseApiResponse<Api, Url, Method>;

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import { QueryErrorResetBoundary } from "@superweb/api";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useTypo, useUiColors, Button } from "@superweb/ui";
import { cssFns } from "@superweb/css";

import { Message, useMessage } from "#internal/intl";
import { ErrorBoundary } from "#internal/ui/error-boundary";

export const Errors = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          resetKeys={[location.key]}
          onReset={({ reason }) => (reason === "keys" ? reset() : undefined)}
          fallbackRender={() => <ErrorMessage />}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

const ErrorMessage = () => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const message = useMessage();

  return (
    <>
      <Helmet>
        <title>
          {message({
            id: "5a323240-3a5b-4235-bdaa-ed3aecc0b4c8",
            context: "Error page. Tab title",
            default: "Error",
          })}
        </title>
      </Helmet>
      <div
        css={{
          ...cssFns.placeContent("center"),
          display: "grid",
          textAlign: "center",
          minHeight: "100vh",
          backgroundColor: uiColors.background,
        }}
      >
        <div
          css={{
            display: "grid",
            minWidth: "324px",
            ...cssFns.placeContent("center"),
          }}
        >
          <h1
            css={{
              ...cssFns.margin("0"),
              ...typo({
                level: "title4",
                weight: "regular",
                density: "normal",
              }),
              marginBlockEnd: "1px",
            }}
          >
            <Message
              id="4c265af3-09f7-4647-bbb8-65dc4bf59e46"
              context="Error page. Unexpected error title"
              default="Unexpected error"
            />
          </h1>
          <span
            css={{
              marginBlockEnd: "16px",
              ...typo({
                level: "body2",
                weight: "regular",
                density: "normal",
              }),
            }}
          >
            <Message
              id="9046467b-230b-44bc-80c4-1e3f867c098b"
              context="Error page. Unexpected error description"
              default="Refresh the page or try again later"
            />
          </span>
          <Button
            view="action"
            onPress={() => window.location.reload()}
            text={message({
              id: "9bc7d501-3e7d-4628-a42e-4f7e0255014c",
              context: "Error page. Reload button text",
              default: "Reload",
            })}
          />
        </div>
      </div>
    </>
  );
};

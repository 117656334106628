/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Suspense, useEffect } from "react";
import {
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { QueryClient, QueryClientProvider } from "@superweb/api";
import { CssOptions } from "@superweb/css";
import {
  AppContainer,
  useUiColors,
  Spin,
  Theme,
  usePreviousState,
  Messages as SuperwebUiMessages,
} from "@superweb/ui";
import { useLocale } from "@superweb/intl";

import { useV1ParkInfoGet } from "@fleet/api/fleet-forms";
import { YangoTheme, Messages as FleetUiMessages } from "@fleet/ui";

import { Form } from "./form";
import { Flags } from "./flags";
import { Messages } from "./intl";
import { AppLocale } from "./app-locale";
import { stringSearchParamParser, useSearchParams } from "./router";
import { Errors } from "./errors";
import { NotFound } from "./ui";
import { useLogHit } from "./analytics/logActions";
import { initGtag } from "./analytics/gtag";
import { initFBQ } from "./analytics/fbq";
import { initTTQ } from "./analytics/ttq";
import "./analytics/initYM";
import "./analytics/initFB";
import "./analytics/initTikTok";
import faviconYango from "./images/favicon-yango.png";
import faviconYandex from "./images/favicon-yandex.png";
import { ArchivePage } from "./form/archive";

const queryClient = new QueryClient();

const useLogLocation = (logHit: (url: string) => void) => {
  const location = useLocation();
  const prevPathname = usePreviousState(location.pathname);

  useEffect(() => {
    if (location.pathname !== prevPathname) {
      logHit(`${location.pathname}${location.search}`);
    }
  }, [location, prevPathname, logHit]);
};

const Root = () => {
  return (
    <AppContainer>
      <Flags>
        <Suspense
          fallback={
            <div
              css={{
                display: "grid",
                alignContent: "center",
                justifyContent: "center",
                minHeight: "100vh",
              }}
            >
              <Spin />
            </div>
          }
        >
          <SuperwebUiMessages>
            <FleetUiMessages>
              <Messages>
                <Errors>
                  <AppContent />
                </Errors>
              </Messages>
            </FleetUiMessages>
          </SuperwebUiMessages>
        </Suspense>
      </Flags>
    </AppContainer>
  );
};

const router = createBrowserRouter([{ path: "*", Component: Root }]);

export const App = () => {
  const yangoBrand = window.location.hostname.split(".").includes("yango");

  return (
    <CssOptions
      value={{
        nonce: (
          document.querySelector(
            "meta[property=csp-nonce]",
          ) as HTMLMetaElement | null
        )?.nonce,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AppLocale>
          <HelmetProvider>
            <Theme value={yangoBrand ? YangoTheme : undefined}>
              <RouterProvider router={router} />
            </Theme>
          </HelmetProvider>
        </AppLocale>
      </QueryClientProvider>
    </CssOptions>
  );
};

const AppContent = () => {
  const uiColors = useUiColors();
  const locale = useLocale();
  const logHit = useLogHit();

  useLogLocation(logHit);

  const [params] = useSearchParams({
    ref_id: stringSearchParamParser(),
    iframe: stringSearchParamParser(),
    skip_vehicle: stringSearchParamParser(),
    skip_license: stringSearchParamParser(),
    specification: stringSearchParamParser(),
  });

  const isIframe = params.iframe === "true";

  const isDelivery = params.specification === "delivery";

  const { data: parkInfo } = useV1ParkInfoGet(
    {
      headers: {
        language: locale.toString(),
      },
      query: { ref_id: params.ref_id },
    },
    {
      enabled: Boolean(params.ref_id),
      suspense: true,
    },
  );

  useEffect(() => {
    if (parkInfo?.status === 200) {
      switch (parkInfo.data.channel_id) {
        case "google_ads":
          const ga_id = parkInfo.data.google_adwords_id;
          initGtag(ga_id);
          break;
        case "facebook":
          const pixel_id = parkInfo.data.fb_pixel_id;
          initFBQ(pixel_id);
          break;
        case "tik_tok":
          const tiktok_pixel_id = parkInfo.data.tiktok_pixel_id;
          initTTQ(tiktok_pixel_id);
          break;
        default:
          break;
      }
    }
  }, [parkInfo]);

  if (!params.ref_id || !parkInfo || parkInfo.status === 400)
    return <NotFound />;

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href={parkInfo.data.brand === "yango" ? faviconYango : faviconYandex}
          sizes="16x16"
        />
      </Helmet>
      <Theme value={parkInfo.data.brand === "yango" ? YangoTheme : undefined}>
        <div
          css={{
            display: "grid",
            minWidth: "min-content",
            minHeight: "100vh",
            gridTemplateRows: "minmax(min-content, 100vh) 1fr",
            isolation: "isolate",
            ...(isIframe && {
              maxHeight: "600px",
              maxWidth: "600px",
            }),
          }}
        >
          <div
            css={{
              display: "grid",
              minHeight: "100vh",
              backgroundColor: uiColors.background,
            }}
          >
            <Suspense
              fallback={
                <div
                  css={{
                    display: "grid",
                    minWidth: "min-content",
                    minHeight: "100vh",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              }
            >
              <Routes>
                <Route
                  path="/forms"
                  element={
                    parkInfo.status === 200 ? (
                      <Form
                        parkInfo={parkInfo.data}
                        refId={params.ref_id}
                        isIframe={isIframe}
                        isDelivery={isDelivery}
                      />
                    ) : (
                      <ArchivePage
                        parkInfo={parkInfo.data}
                        isIframe={isIframe}
                      />
                    )
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </Theme>
    </>
  );
};

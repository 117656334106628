/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, useCallback, useState } from "react";
import { useSwitch, useFocusRing, useId, mergeProps } from "react-aria";
import { useToggleState } from "react-stately";

import { cssFns } from "@superweb/css";

import { useUiColors } from "../theme";
import { FieldMessage } from "../fields/field-message";
import { ToggleLabelContent } from "../fields/toggle-label-content";

export type SwitchState = {
  /**
   * Current switch value.
   */
  value: boolean;

  /**
   * When set `false` the error message is not visible even if is set.
   * Is set on change depending on the interaction.
   * Can be set externally to force hide/show the error message.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorVisible?: boolean;

  /**
   * The error message associated with the field.
   * Visible only when `errorVisible` is `true`.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorMessage?: string;
};

/**
 * Switch represents on/off values.
 */
export const Switch = ({
  state,
  onChange,
  label,
  description,
  disabled = false,
  variant = "fill",
  labelAlign = "start",
}: {
  /**
   * Text for field's label, that describes field's meaning.
   * No need to specify input examples in label's text.
   * The label is used for the accessibility of the element
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   * https://www.w3.org/WAI/tutorials/forms/labels/
   */
  label: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description
   */
  description?: string;

  /**
   * Current field's state.
   * The state stores the interactive fields, that change when interacting with the component.
   */
  state: SwitchState;

  /**
   * Callback fired when the state is changed.
   * @param state - Recommended state for the component after the change.
   */
  onChange: (state: SwitchState) => void;

  /**
   * 	If true, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Variant of switch.
   * @defaultValue 'fill'
   */
  variant?: "fill" | "ghost";

  /**
   * Switch's label alignment.
   * @defaultValue 'start'
   */
  labelAlign?: "start" | "end";
}) => {
  const uiColors = useUiColors();
  const inputRef = useRef<HTMLInputElement>(null);

  const descriptionId = useId();

  const onValueChange = useCallback(
    (isSelected: boolean) =>
      onChange({
        value: isSelected,
        errorVisible: false,
        errorMessage: undefined,
      }),
    [onChange],
  );

  const toggleState = useToggleState({
    isSelected: state.value,
    onChange: onValueChange,
  });

  const { inputProps } = useSwitch(
    {
      isSelected: state.value,
      onChange: onValueChange,
      children: label,
      isDisabled: disabled,
      "aria-describedby": descriptionId,
    },
    toggleState,
    inputRef,
  );

  const { isFocusVisible, focusProps } = useFocusRing();

  const showError = state.errorVisible && state.errorMessage;

  const SwitchInternal = (
    <div
      css={{
        position: "relative",
        display: "flex",
        height: "32px",
        width: "52px",
        alignItems: "center",
        justifyContent: state.value ? "flex-end" : "flex-start",
        backgroundColor: state.value
          ? uiColors.controlMain
          : uiColors.controlMinor,
        ...cssFns.padding("0"),
        ...cssFns.border({ radius: "100px" }),
        ...(isFocusVisible &&
          cssFns.boxShadow({
            spreadRadius: "2px",
            color: uiColors.focus,
          })),
      }}
    >
      <input
        {...mergeProps(inputProps, focusProps)}
        css={{
          width: "100%",
          height: "100%",
          position: "absolute",
          opacity: "0",
          cursor: disabled ? "default" : "pointer",
          ...cssFns.margin("0"),
        }}
        aria-invalid={Boolean(showError)}
        ref={inputRef}
      />
      <div
        css={{
          width: "29px",
          height: "29px",
          backgroundColor: uiColors.everFront,
          ...cssFns.border({ radius: "50%" }),
          ...cssFns.margin("2px"),
          ...(!disabled &&
            cssFns.boxShadow({
              offsetX: "0px",
              offsetY: "2px",
              blurRadius: "5px",
              color: cssFns.setOpacity(uiColors.everBack, 0.1),
            })),
        }}
      />
    </div>
  );

  return (
    <div
      css={{
        display: "grid",
        wordBreak: "break-word",
        opacity: disabled ? "0.5" : "1",
      }}
    >
      <label
        css={{
          display: "inline-grid",
          justifyContent: labelAlign === "start" ? "space-between" : "start",
          alignItems: "center",
          gridAutoFlow: "column",
          columnGap: "16px",
          backgroundColor:
            variant === "fill"
              ? uiColors.controlMinor
              : cssFns.transparentize(uiColors.everBack, 1),
          boxSizing: "border-box",
          cursor: disabled ? "default" : "pointer",
          ...cssFns.border({ radius: "16px" }),
          ...cssFns.padding("12px", "12px"),
        }}
      >
        {labelAlign === "end" && SwitchInternal}
        {
          <ToggleLabelContent
            label={label}
            description={description}
            descriptionId={descriptionId}
          />
        }
        {labelAlign === "start" && SwitchInternal}
      </label>

      {showError && state.errorMessage && (
        <FieldMessage type={"error"} messageProps={{ id: descriptionId }}>
          {state.errorMessage}
        </FieldMessage>
      )}
    </div>
  );
};

export const createSwitchState = (defaultValue?: SwitchState): SwitchState => {
  return {
    value: false,
    errorVisible: false,
    errorMessage: undefined,
    ...defaultValue,
  };
};

export const useSwitchState = (defaultValue?: SwitchState) => {
  return useState<SwitchState>(createSwitchState(defaultValue));
};

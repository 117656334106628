/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Temporal } from "@js-temporal/polyfill";
import { useEffect, useState } from "react";

import {
  Button,
  ExperimentalFormRow,
  TextField,
  useTextFieldState,
  useTypo,
  useUiColors,
  type SwitchState,
} from "@superweb/ui";
import { useFormat, useLocale } from "@superweb/intl";

import {
  useV1PhoneCommitPost,
  useV1PhoneSubmitPost,
} from "@fleet/api/fleet-forms";

import { useMessage } from "#internal/intl";
import { useErrorMessages } from "#internal/utils";

export const PhoneVerificator = ({
  phone,
  onChange,
  phoneVerificationState,
  phoneVerificationId,
  setPhoneVerificationId,
  isCodeSent,
  setIsCodeSent,
}: {
  phone?: string;
  onChange: (state: SwitchState) => void;
  phoneVerificationState: SwitchState;
  phoneVerificationId: string;
  setPhoneVerificationId: (value: string) => void;
  isCodeSent: boolean;
  setIsCodeSent: (value: boolean) => void;
}) => {
  const [codeLength, setCodeLength] = useState(6);
  const [sendCodeTimeout, setSendCodeTimeout] = useState(0);

  const typo = useTypo();
  const locale = useLocale();
  const message = useMessage();
  const uiColors = useUiColors();
  const { formatDuration } = useFormat();
  const errorMessages = useErrorMessages();

  const [phoneCheckCode, setPhoneCheckCode] = useTextFieldState();
  const [phoneVerificationError, setPhoneVerificationError] = useState("");

  const [isSendCodeButtonTimer, setIsSendCodeButtonTimer] = useState(false);

  const { mutate: sendCode, isLoading: isPhoneCodeSending } =
    useV1PhoneSubmitPost();

  const { data: isPhoneVerified, isLoading: isPhoneCodeChecking } =
    useV1PhoneCommitPost(
      {
        body: {
          confirmation_id: phoneVerificationId,
          code: phoneCheckCode.value,
        },
        headers: {
          "content-type": "application/json",
          language: locale.toString(),
        },
      },
      {
        enabled:
          !!phoneCheckCode.value &&
          !!phoneVerificationId &&
          phoneCheckCode.value.length === codeLength,
      },
    );

  useEffect(() => {
    if (
      !isPhoneVerified?.ok &&
      phoneCheckCode.value.length === codeLength &&
      phoneVerificationState.errorMessage !== isPhoneVerified?.error.message
    ) {
      onChange({
        value: false,
        errorMessage: isPhoneVerified?.error.message,
        errorVisible: !!isPhoneVerified?.error.message,
      });
    }
    if (isPhoneVerified?.ok && !phoneVerificationState.value) {
      setIsSendCodeButtonTimer(false);
      setSendCodeTimeout(0);
      onChange({ value: true, errorMessage: undefined, errorVisible: false });
    }
  }, [
    isPhoneVerified,
    message,
    phoneCheckCode,
    onChange,
    codeLength,
    phoneVerificationState,
  ]);

  useEffect(() => {
    if (sendCodeTimeout > 0) {
      const timer = setTimeout(() => {
        if (sendCodeTimeout - 1 <= 0) {
          setIsSendCodeButtonTimer(false);
        }
        setSendCodeTimeout((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return;
  }, [sendCodeTimeout]);

  return (
    <ExperimentalFormRow columnsCount={2}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          rowGap: "4px",
          justifyContent: "start",
        }}
      >
        <TextField
          disabled={
            isPhoneCodeSending || !phoneVerificationId || isPhoneVerified?.ok
          }
          label={message({
            id: "b1c57106-577f-4c57-acc1-b80600718f16",
            context: "Form. Check phone code field",
            default: "Enter code *",
          })}
          state={phoneCheckCode}
          onChange={(state) => {
            setPhoneVerificationError("");
            setPhoneCheckCode(state);
            if (state.value.length <= 0) {
              onChange({
                value: false,
                errorMessage: errorMessages.requiredField,
                errorVisible: true,
              });
            } else if (state.value.length !== codeLength) {
              onChange({
                value: false,
                errorMessage: message({
                  id: "2ab8a467-4d37-4ea5-b798-d4dd41e8712c",
                  context: "Form. Check phone code field. Wrong code error",
                  default: "Invalid code",
                }),
                errorVisible: true,
              });
            }
          }}
        />
        {phoneVerificationState.errorVisible &&
          phoneVerificationState.errorMessage && (
            <span
              css={{
                paddingInlineStart: "16px",
                paddingInlineEnd: "16px",
                color: uiColors.statuses.danger,
                ...typo({
                  level: "caption1",
                  weight: "regular",
                  density: "tight",
                }),
              }}
            >
              {phoneVerificationState.errorMessage}
            </span>
          )}
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          rowGap: "4px",
          justifyContent: "start",
        }}
      >
        <Button
          disabled={
            !phone ||
            isPhoneCodeSending ||
            isPhoneCodeChecking ||
            isSendCodeButtonTimer ||
            isPhoneVerified?.ok
          }
          progress={isPhoneCodeSending}
          onPress={() => {
            if (phone) {
              setPhoneVerificationError("");
              setPhoneCheckCode({ value: "" });
              sendCode(
                {
                  headers: {
                    "content-type": "application/json",
                    language: locale.toString(),
                  },
                  body: {
                    phone: phone,
                  },
                },
                {
                  onSuccess: (response) => {
                    if (response.ok) {
                      setPhoneVerificationId(response.data.confirmation_id);
                      setIsCodeSent(true);
                      setIsSendCodeButtonTimer(true);
                      if (response.data.code_length)
                        setCodeLength(response.data.code_length);
                      if (response.data.deny_resend_until) {
                        const timeout = Math.ceil(
                          response.data.deny_resend_until - Date.now() / 1000,
                        );
                        setSendCodeTimeout(timeout > 0 ? timeout : 0);
                      }
                    } else {
                      setPhoneVerificationError(response.error.message);
                    }
                  },
                  onError: () => {
                    setPhoneVerificationError(
                      message({
                        id: "86a104c4-7940-4235-9b77-e561b1eff252",
                        context: "Form. Get code button. Error",
                        default: "Something went wrong. Please try again",
                      }),
                    );
                  },
                },
              );
            }
          }}
          size="l"
          text={
            !isCodeSent
              ? message({
                  id: "e020fdbe-ab99-4e6d-ba07-d2bc03a83fca",
                  context: "Form. Get code button. Default state",
                  default: "Get code",
                })
              : isSendCodeButtonTimer
                ? message({
                    id: "50a202ae-c97f-4ddd-83d6-c300f235d61b",
                    context:
                      "Form. Get code button. Waiting to send code again state",
                    default: "Another code {value}",
                    values: {
                      value: formatDuration(
                        Temporal.Duration.from({
                          seconds: sendCodeTimeout,
                        }).round({
                          largestUnit: "seconds",
                          smallestUnit: "seconds",
                        }),
                      ),
                    },
                  })
                : message({
                    id: "e020fdbe-ab99-4e6d-ba07-d2bc03a84fca",
                    context: "Form. Get code button. Send code again state",
                    default: "Another code",
                  })
          }
        />
        {phoneVerificationError && (
          <span
            css={{
              paddingInlineStart: "16px",
              paddingInlineEnd: "16px",
              color: uiColors.statuses.danger,
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "tight",
              }),
            }}
          >
            {phoneVerificationError}
          </span>
        )}
      </div>
    </ExperimentalFormRow>
  );
};
